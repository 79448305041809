<template>
  <div class="typesetting-page">
    <div class="page-title">
      界面设置
    </div>
    <el-tabs tab-position="left" style="height: 100%;">
      <el-tab-pane label="快捷访问入口">
        <el-form :model="quick" :rules="rules" ref="quickFormRef" label-width="200px" class="from-area">
          <el-form-item label="图标" prop="icon1">
            <file-pond
              name="icon1"
              ref="imgpond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="quick.icon1"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="Icon1OnProcess"
              :onremovefile="RemoveIcon1"
            />
          </el-form-item>
          <el-form-item label="标题1:" prop="title1">
            <el-input v-model="quick.title1"></el-input>
          </el-form-item>
          <el-form-item label="小标题1:" prop="content1">
            <el-input v-model="quick.content1"></el-input>
          </el-form-item>
          <el-form-item label="链接1:" prop="link1">
            <el-input v-model="quick.link1"></el-input>
          </el-form-item>
          <el-form-item label="图标2" prop="icon2">
            <file-pond
              name="icon2"
              ref="imgpond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="quick.icon2"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="Icon2OnProcess"
              :onremovefile="RemoveIcon2"
            />
          </el-form-item>
          <el-form-item label="标题2:" prop="title2">
            <el-input v-model="quick.title2"></el-input>
          </el-form-item>
          <el-form-item label="小标题2:" prop="content2">
            <el-input v-model="quick.content2"></el-input>
          </el-form-item>
          <el-form-item label="链接2:" prop="link2">
            <el-input v-model="quick.link2"></el-input>
          </el-form-item>
          <el-form-item label="图标3" prop="icon3">
            <file-pond
              name="icon3"
              ref="imgpond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="quick.icon3"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="Icon3OnProcess"
              :onremovefile="RemoveIcon3"
            />
          </el-form-item>
          <el-form-item label="标题3:" prop="title3">
            <el-input v-model="quick.title3"></el-input>
          </el-form-item>
          <el-form-item label="小标题3:" prop="content3">
            <el-input v-model="quick.content3"></el-input>
          </el-form-item>
          <el-form-item label="链接3:" prop="link3">
            <el-input v-model="quick.link3"></el-input>
          </el-form-item>
          <el-form-item label="图标4" prop="icon4">
            <file-pond
              name="icon4"
              ref="imgpond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="quick.icon4"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="Icon4OnProcess"
              :onremovefile="RemoveIcon4"
            />
          </el-form-item>
          <el-form-item label="标题4:" prop="title4">
            <el-input v-model="quick.title4"></el-input>
          </el-form-item>
          <el-form-item label="小标题4:" prop="content4">
            <el-input v-model="quick.content4"></el-input>
          </el-form-item>
          <el-form-item label="链接4:" prop="link4">
            <el-input v-model="quick.link4"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitQuickForm">提交信息</el-button>
            <el-button @click="resetQuickForm">重置</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="会展工具">
        <el-form :model="tool" :rules="rules" ref="toolFormRef" label-width="200px" class="from-area">
          <el-form-item label="图标1" prop="toolicon1">
            <file-pond
              name="toolicon1"
              ref="imgpond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="tool.toolicon1"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="ToolIcon1OnProcess"
              :onremovefile="RemoveToolIcon1"
            />
          </el-form-item>
          <el-form-item label="标题1:" prop="tooletitle1">
            <el-input v-model="tool.tooletitle1"></el-input>
          </el-form-item>
          <el-form-item label="链接1:" prop="toollink1">
            <el-input v-model="tool.toollink1"></el-input>
          </el-form-item>
          <el-form-item label="图标2" prop="toolicon2">
            <file-pond
              name="toolicon2"
              ref="imgpond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="tool.toolicon2"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="ToolIcon2OnProcess"
              :onremovefile="RemoveToolIcon2"
            />
          </el-form-item>
          <el-form-item label="标题2:" prop="tooletitle2">
            <el-input v-model="tool.tooletitle2"></el-input>
          </el-form-item>
          <el-form-item label="链接2:" prop="toollink2">
            <el-input v-model="tool.toollink2"></el-input>
          </el-form-item>
          <el-form-item label="图标3" prop="toolicon3">
            <file-pond
              name="toolicon3"
              ref="imgpond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="tool.toolicon3"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="ToolIcon3OnProcess"
              :onremovefile="RemoveToolIcon3"
            />
          </el-form-item>
          <el-form-item label="标题3:" prop="tooletitle3">
            <el-input v-model="tool.tooletitle3"></el-input>
          </el-form-item>
          <el-form-item label="链接3:" prop="toollink3">
            <el-input v-model="tool.toollink3"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitToolForm">提交信息</el-button>
            <el-button @click="resetToolForm">重置</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Typesetting',
  data () {
    return {
      quick: {
        icon1: [],
        icon1_id: '',
        title1: '',
        content1: '',
        link1: '',
        icon2: [],
        icon2_id: '',
        title2: '',
        content2: '',
        link2: '',
        icon3: [],
        icon3_id: '',
        title3: '',
        content3: '',
        link3: '',
        icon4: [],
        icon4_id: '',
        title4: '',
        content4: '',
        link4: ''
      },
      tool: {
        toolicon1: [],
        toolicon1_id: '',
        tooletitle1: '',
        toollink1: '',
        toolicon2: [],
        toolicon2_id: '',
        tooletitle2: '',
        toollink2: '',
        toolicon3: [],
        toolicon3_id: '',
        tooletitle3: '',
        toollink3: ''
      },
      rules: {},
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      },
      disabled: false
    }
  },
  created () {
    this.get_type_info()
  },
  methods: {
    async get_type_info () {
      const { data: res } = await this.$http.get('admin/typesetting')
      if (res.meta.status === 200) {
        if (res.data.quick.icon1) {
          this.quick.icon1 = [{
            // the server file reference
            source: res.data.quick.icon1,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.quick.icon1 = []
        }
        this.quick.icon1_id = res.data.quick.icon1_id
        if (res.data.quick.icon2) {
          this.quick.icon2 = [{
            // the server file reference
            source: res.data.quick.icon2,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.quick.icon2 = []
        }
        this.quick.icon2_id = res.data.quick.icon2_id
        if (res.data.quick.icon3) {
          this.quick.icon3 = [{
            // the server file reference
            source: res.data.quick.icon3,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.quick.icon3 = []
        }
        this.quick.icon3_id = res.data.quick.icon3_id
        if (res.data.quick.icon4) {
          this.quick.icon4 = [{
            // the server file reference
            source: res.data.quick.icon4,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.quick.icon4 = []
        }
        this.quick.icon4_id = res.data.quick.icon4_id
        this.quick.title1 = res.data.quick.title1
        this.quick.title2 = res.data.quick.title2
        this.quick.title3 = res.data.quick.title3
        this.quick.title4 = res.data.quick.title4
        this.quick.content1 = res.data.quick.content1
        this.quick.content2 = res.data.quick.content2
        this.quick.content3 = res.data.quick.content3
        this.quick.content4 = res.data.quick.content4
        this.quick.link1 = res.data.quick.link1
        this.quick.link2 = res.data.quick.link2
        this.quick.link3 = res.data.quick.link3
        this.quick.link4 = res.data.quick.link4
        if (res.data.tool.toolicon1) {
          this.tool.toolicon1 = [{
            // the server file reference
            source: res.data.tool.toolicon1,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.tool.toolicon1 = []
        }
        this.tool.toolicon1_id = res.data.tool.toolicon1_id
        if (res.data.tool.toolicon2) {
          this.tool.toolicon2 = [{
            // the server file reference
            source: res.data.tool.toolicon2,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.tool.toolicon2 = []
        }
        this.tool.toolicon2_id = res.data.tool.toolicon2_id
        if (res.data.tool.toolicon3) {
          this.tool.toolicon3 = [{
            // the server file reference
            source: res.data.tool.toolicon3,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.tool.toolicon3 = []
        }
        this.tool.toolicon3_id = res.data.tool.toolicon3_id
        this.tool.tooletitle1 = res.data.tool.tooletitle1
        this.tool.tooletitle2 = res.data.tool.tooletitle2
        this.tool.tooletitle3 = res.data.tool.tooletitle3
        this.tool.toollink1 = res.data.tool.toollink1
        this.tool.toollink2 = res.data.tool.toollink2
        this.tool.toollink3 = res.data.tool.toollink3
      }
    },
    async submitQuickForm () {
      this.$refs.quickFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/typequick', this.quick)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_type_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetQuickForm () {
      this.$refs.quickFormRef.resetFields()
    },
    async submitToolForm () {
      this.$refs.toolFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/typetool', this.tool)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_type_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetToolForm () {
      this.$refs.toolFormRef.resetFields()
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    Icon1OnProcess (error, file) {
      console.log(error)
      this.quick.icon1_id = file.serverId
    },
    RemoveIcon1 () {
      this.quick.icon1_id = ''
    },
    Icon2OnProcess (error, file) {
      console.log(error)
      this.quick.icon2_id = file.serverId
    },
    RemoveIcon2 () {
      this.quick.icon2_id = ''
    },
    Icon3OnProcess (error, file) {
      console.log(error)
      this.quick.icon3_id = file.serverId
    },
    RemoveIcon3 () {
      this.quick.icon3_id = ''
    },
    Icon4OnProcess (error, file) {
      console.log(error)
      this.quick.icon4_id = file.serverId
    },
    RemoveIcon4 () {
      this.quick.icon4_id = ''
    },
    ToolIcon1OnProcess (error, file) {
      console.log(error)
      this.tool.toolicon1_id = file.serverId
    },
    RemoveToolIcon1 () {
      this.tool.toolicon1 = ''
    },
    ToolIcon2OnProcess (error, file) {
      console.log(error)
      this.tool.toolicon2_id = file.serverId
    },
    RemoveToolIcon2 () {
      this.tool.toolicon2 = ''
    },
    ToolIcon3OnProcess (error, file) {
      console.log(error)
      this.tool.toolicon3_id = file.serverId
    },
    RemoveToolIcon3 () {
      this.tool.toolicon3 = ''
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
