<template>
  <div class="upload-page">
    <div class="page-title">
      上传设置
    </div>
    <div class="page-body">
      <el-form :model="pageForm" :rules="rules" ref="pageFormRef" label-width="160px" class="from-area">
        <el-form-item label="保存文件地址:" prop="upload_address">
          <el-switch v-model="pageForm.upload_address" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" active-text="本地" inactive-text="七牛云"></el-switch>
          <span class="input-tips">选择七牛云，需要先配置七牛云</span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交信息</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Upload',
  data () {
    return {
      pageForm: {
        upload_address: 0
      },
      rules: {}
    }
  },
  created () {
    this.get_page_info()
  },
  methods: {
    // 获取站点信息
    async get_page_info () {
      const { data: res } = await this.$http.get('admin/upload-setting')
      if (res.meta.status === 200) {
        this.pageForm.upload_address = res.data.upload_address
      }
    },
    async submitForm () {
      this.$refs.pageFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/upload-setting', this.pageForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_page_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.pageFormRef.resetFields()
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
.input-tips{
  font-size: 12px;
  color: #909399;
  display: block;
}
</style>
