<template>
  <div class="left-menu-page">
    <el-menu :default-active="activeUrl" :collapse="isCollapse" router :unique-opened="true">
      <div v-for="item in menus" :key="item.id">
        <!-- 不存在子菜单 -->
        <template v-if="item.children && item.children.length == 0">
          <el-menu-item :key="item.id" :index="item.path" @click="saveNavUrl(item.path)">
            <i :class="item.icon" style="margin-right: 0.4rem;"></i>
            <span slot="title">{{ item.name }}</span>
          </el-menu-item>
        </template>
        <!-- 有子菜单 -->
        <el-submenu v-else :key="item.id" :index="item.id + ''">
          <template slot="title">
            <i :class="item.icon" style="margin-right: 0.4rem;"></i>
            <span>{{item.name}}</span>
          </template>
          <el-menu-item-group>
            <template v-for="child in item.children">
              <el-menu-item :index="child.path" :key="child.id" @click="saveNavUrl(child.path)">
                <i :class="child.icon"></i>
                {{ child.name }}
              </el-menu-item>
            </template>
          </el-menu-item-group>
        </el-submenu>
      </div>
    </el-menu>
    <div class="change-collapse">
      <el-switch v-model="isCollapse" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LeftMenu',
  data () {
    return {
      isCollapse: false,
      menus: [],
      activeUrl: ''
    }
  },
  created () {
    this.get_menus()
    this.activeUrl = window.sessionStorage.getItem('activeUrl')
  },
  methods: {
    async get_menus () {
      const { data: res } = await this.$http.get('/admin/menus')
      if (res.meta.status === 200) {
        this.menus = res.data
      }
    },
    // 保存菜单的激活属性
    saveNavUrl (activeUrl) {
      window.sessionStorage.setItem('activeUrl', activeUrl)
    }
  }
}
</script>

<style scoped>
.el-menu{
  border: none;
}
.el-menu:not(.el-menu--collapse) {
  width: 200px;
}
.collapse{
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(84,100,120,0.8);
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*隐藏文字*/
.el-menu--collapse  .el-submenu__title span{
  display: none;
}
/*隐藏 > */
/deep/.el-menu--collapse .el-submenu__title .el-icon-arrow-right{
  display: none;
}
.change-collapse{
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100px;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  box-shadow: 0px -8px 8px 0px rgba(0,0,0,0.06);
}
.left-menu-page{
  width: 100%;
  height: calc(100% - 100px);
  position: static;
  overflow-y: scroll;
  background-color: #FFFFFF;
  text-align: left;
}
.left-menu-page::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 4px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
.left-menu-page::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius   : 10px;
  background-color: rgba(103, 194, 58, 0.4);
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(103, 194, 58, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
.left-menu-page::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background   : #FFFFFF;
  border-radius: 10px;
  border: 0;
}
</style>
