<template>
  <div class="administrators-page">
    <div class="page-title">
      管理员管理
    </div>
    <div class="page-body">
      <div class="btn-list">
        <el-button type="primary" plain @click="addAdmin">添加管理员</el-button>
      </div>
      <el-table :data="allUser" stripe style="width: 100%">
        <el-table-column prop="user_id" label="#ID" width="180"></el-table-column>
        <el-table-column prop="username" label="用户名" width="180"></el-table-column>
        <el-table-column prop="realname" label="姓名"></el-table-column>
        <el-table-column prop="role_name" label="已分配角色"></el-table-column>
        <el-table-column prop="avatar" label="头像">
          <template slot-scope="scope">
            <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 添加管理员 -->
    <el-dialog title="添加管理员" :close-on-click-modal="false" :visible.sync="addDialogVisible" width="30%" :before-close="addHandleClose">
      <el-form :model="addForm" :rules="addRules" ref="addFormRef" label-width="100px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="addForm.password"></el-input>
        </el-form-item>
        <el-form-item label="用户角色" prop="role">
          <el-select v-model="addForm.role" placeholder="请选择用户角色">
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in roleList" :key="index" :index="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定手机号" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="addForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="addForm.realname"></el-input>
        </el-form-item>
        <el-form-item label="用户头像" prop="avatar">
          <file-pond
            name="avatar"
            ref="pond"
            label-idle="Drop files here..."
            v-bind:allow-multiple="false"
            accepted-file-types="image/jpeg, image/png"
            :server="uploadServer"
            v-bind:files="addForm.avatar"
            v-on:init="handleFilePondInit"
            :chunkUploads="true"
            maxFileSize="2MB"
            :onprocessfile="onAddfile"
            :onremovefile="addRemoveAvatar"
          />
        </el-form-item>
        <el-form-item label="介绍" prop="introduce">
          <el-input type="textarea" placeholder="请输入内容" v-model="addForm.introduce" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="账号到期时间" prop="end_time">
          <el-date-picker v-model="addForm.end_time" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="email">
          <el-input v-model="addForm.email"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="addForm.sex" placeholder="请选性别">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="帐号状态" prop="locked">
          <el-switch v-model="addForm.locked" :active-value="1" :inactive-value="0" active-text="正常" inactive-text="锁定"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSure">确 定</el-button>
      </span>
    </el-dialog>
    <!--修改管理员-->
    <el-dialog title="修改管理员" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="30%" :before-close="editHandleClose">
      <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="100px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username"></el-input>
        </el-form-item>
        <el-form-item label="用户角色" prop="role">
          <el-select v-model="editForm.role" placeholder="请选择用户角色">
            <el-option :label="item.name" :value="item.id" v-for="(item, index) in roleList" :key="index" :index="index"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="绑定手机号" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="editForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="姓名" prop="realname">
          <el-input v-model="editForm.realname"></el-input>
        </el-form-item>
        <el-form-item label="用户头像" prop="avatar">
          <file-pond
            name="avatar"
            ref="pond"
            label-idle="Drop files here..."
            v-bind:allow-multiple="false"
            accepted-file-types="image/jpeg, image/png"
            :server="uploadServer"
            v-bind:files="editForm.avatar"
            v-on:init="handleFilePondInit"
            :chunkUploads="true"
            maxFileSize="2MB"
            :onprocessfile="onEditfile"
            :onremovefile="editRemoveAvatar"
          />
        </el-form-item>
        <el-form-item label="介绍" prop="introduce">
          <el-input type="textarea" placeholder="请输入内容" v-model="editForm.introduce" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="账号到期时间" prop="end_time">
          <el-date-picker v-model="editForm.end_time" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="联系邮箱" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select v-model="editForm.sex" placeholder="请选性别">
            <el-option label="男" :value="1"></el-option>
            <el-option label="女" :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="帐号状态" prop="locked">
          <el-switch v-model="editForm.locked" :active-value="1" :inactive-value="0" active-text="正常" inactive-text="锁定"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Administrators',
  data () {
    return {
      allUser: [],
      addDialogVisible: false,
      addForm: {
        username: '',
        password: '',
        role: '',
        phone: '',
        nickname: '',
        realname: '',
        avatar: [],
        avatarId: '',
        introduce: '',
        end_time: '',
        email: '',
        sex: '',
        locked: 1
      },
      roleList: [],
      addRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        role: [
          { required: true, message: '请选择用户角色身份', trigger: 'change' }
        ],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      },
      editDialogVisible: false,
      editForm: {
        id: '',
        username: '',
        role: '',
        phone: '',
        nickname: '',
        realname: '',
        avatar: [],
        avatarId: '',
        introduce: '',
        end_time: '',
        email: '',
        sex: '',
        locked: 1
      },
      editRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        role: [
          { required: true, message: '请选择用户角色身份', trigger: 'change' }
        ]
      },
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      }
    }
  },
  created () {
    this.get_all_admin()
  },
  methods: {
    // 添加管理员按钮
    async addAdmin () {
      // 获取用户角色列表
      const { data: res } = await this.$http.get('admin/role-list')
      if (res.meta.status === 200) {
        this.roleList = res.data
      } else {
        this.$message.error('获取用户角色失败')
      }
      this.addDialogVisible = true
      console.log(this.$FilePond)
    },
    // 确认添加管理员
    async addSure () {
      const that = this
      this.$refs.addFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await that.$http.post('admin/single-admin/1', that.addForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.$refs.addFormRef.resetFields()
            this.get_all_admin()
            this.addDialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 关闭添加弹窗
    addHandleClose () {
      this.$refs.addFormRef.resetFields()
      this.addDialogVisible = false
    },
    async get_all_admin () {
      const { data: res } = await this.$http.get('admin/all-admin')
      if (res.meta.status === 200) {
        this.allUser = res.data
        // this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    onAddfile (error, file) {
      console.log(error)
      this.addForm.avatarId = file.serverId
    },
    onEditfile (error, file) {
      console.log(error)
      this.editForm.avatarId = file.serverId
    },
    addRemoveAvatar () {
      this.addForm.avatarId = ''
    },
    editRemoveAvatar () {
      this.editForm.avatarId = ''
    },
    // 修改管理员按钮
    async handleEdit (userid) {
      const { data: res } = await this.$http.get('admin/single-admin/' + userid)
      if (res.meta.status === 200) {
        this.editForm.id = res.data.id
        this.editForm.username = res.data.username
        this.editForm.role = res.data.role
        this.editForm.phone = res.data.phone
        this.editForm.nickname = res.data.nickname
        this.editForm.realname = res.data.realname
        if (res.data.avatar) {
          this.editForm.avatar = [{
            // the server file reference
            source: res.data.avatar,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.avatar
              }
            }
          }]
        } else {
          this.editForm.avatar = []
        }
        this.editForm.avatarId = res.data.avatarId
        this.editForm.introduce = res.data.introduce
        this.editForm.end_time = res.data.end_time
        this.editForm.email = res.data.email
        this.editForm.sex = res.data.sex
        this.editForm.locked = res.data.locked
      }
      this.editDialogVisible = true
    },
    // 关闭修改弹窗
    editHandleClose () {
      this.$refs.editFormRef.resetFields()
      this.editDialogVisible = false
    },
    // 确认修改管理员
    async editSure () {
      const that = this
      this.$refs.editFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await that.$http.put('admin/single-admin/' + that.editForm.user_id, that.editForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.$refs.editFormRef.resetFields()
            this.get_all_admin()
            this.editDialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 删除管理员
    async handleDelete (id) {
      const { data: res } = await this.$http.delete('admin/single-admin/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.get_all_admin()
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
