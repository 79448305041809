<template>
  <div class="reg-page">
    <div class="page-title">
      注册与访问
    </div>
    <el-form :model="regForm" :rules="rules" ref="regFormRef" label-width="160px" class="from-area">
      <el-form-item label="是否允许前端用户注册:" prop="is_reg">
        <el-switch v-model="regForm.is_reg" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" active-text="关闭注册" inactive-text="开启注册">
        </el-switch>
        <span class="input-tips" style="display: block;">关闭注册后，网站前端页面将隐藏注册入口</span>
      </el-form-item>
      <el-form-item label="关闭注册提示信息:" prop="off_reg_tips" v-if="is_reg === 1">
        <el-input type="textarea" v-model="regForm.off_reg_tips"></el-input>
        <span class="input-tips">当站点关闭注册时的提示信息</span>
      </el-form-item>
      <el-form-item label="通过邮件发送注册链接:" prop="to_mail">
        <el-switch v-model="regForm.to_mail" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" active-text="否" inactive-text="是">
        </el-switch>
        <span class="input-tips" style="display: block;">开启后系统会发一条注册的地址到用户的邮箱，从该地址链接过来的允许注册</span>
      </el-form-item>
      <el-form-item label="注册链接文字:" prop="reg_link_text">
        <el-input v-model="regForm.reg_link_text"></el-input>
        <span class="input-tips">设置站点注册页的链接文字，默认为“立即注册”</span>
      </el-form-item>
      <el-form-item label="新用户注册验证:" prop="reg_ver">
        <el-checkbox-group v-model="regForm.reg_ver" size="medium">
          <el-checkbox-button :label="0">无</el-checkbox-button>
          <el-checkbox-button :label="1">Email 验证</el-checkbox-button>
          <el-checkbox-button :label="2">人工审核</el-checkbox-button>
        </el-checkbox-group>
        <span class="input-tips">
          选择“无”用户可直接注册成功；选择“Email 验证”将向用户注册 Email 发送一封验证邮件以确认邮箱的有效性（开启“通过邮件发送注册链接”该设置自动通过验证）；
          选择“人工审核”将由管理员人工逐个确定是否允许新用户注册
        </span>
      </el-form-item>
      <el-form-item label="发送欢迎信息:" prop="reg_message">
        <el-checkbox-group v-model="regForm.reg_message" size="medium">
          <el-checkbox-button :label="1">发送欢迎信息</el-checkbox-button>
          <el-checkbox-button :label="2">发送欢迎 Email</el-checkbox-button>
        </el-checkbox-group>
        <span class="input-tips">可选择是否自动向新注册用户发送一条欢迎信息</span>
      </el-form-item>
      <el-form-item label="欢迎信息标题:" prop="reg_message_title">
        <el-input v-model="regForm.reg_message_title"></el-input>
        <span class="input-tips">系统发送的欢迎信息的标题，不支持 HTML，不超过 75 字节。</span>
      </el-form-item>
      <el-form-item label="欢迎信息内容:" prop="reg_message_content">
        <el-input type="textarea" v-model="regForm.reg_message_content"></el-input>
        <span class="input-tips">
          系统发送的欢迎信息的内容。
        </span>
      </el-form-item>
      <el-form-item label="显示网站服务条款:" prop="service_text">
        <el-switch v-model="regForm.service_text" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" active-text="关闭" inactive-text="开启">
        </el-switch>
        <span class="input-tips" style="display: block;">新用户注册时显示网站服务条款</span>
      </el-form-item>
      <el-form-item label="服务条款内容:" prop="service_text_content">
        <el-input type="textarea" v-model="regForm.service_text_content"></el-input>
        <span class="input-tips" style="display: block;">网站服务条款的详细内容</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交信息</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'reg',
  data () {
    return {
      regForm: {
        is_reg: 0,
        off_reg_tips: '',
        to_mail: 0,
        reg_link_text: '',
        reg_ver: [],
        reg_message: [],
        reg_message_title: '',
        reg_message_content: '',
        service_text: 0,
        service_text_content: ''
      },
      rules: {
        regname: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.get_reg_info()
  },
  methods: {
    // 获取站点信息
    async get_reg_info () {
      const { data: res } = await this.$http.get('admin/reg')
      if (res.meta.status === 200) {
        this.regForm.is_reg = res.data.is_reg
        this.regForm.off_reg_tips = res.data.off_reg_tips
        this.regForm.to_mail = res.data.to_mail
        this.regForm.reg_link_text = res.data.reg_link_text
        this.regForm.reg_ver = res.data.reg_ver
        this.regForm.reg_message = res.data.reg_message
        this.regForm.reg_message_title = res.data.reg_message_title
        this.regForm.reg_message_content = res.data.reg_message_content
        this.regForm.service_text = res.data.service_text
        this.regForm.service_text_content = res.data.service_text_content
      }
    },
    async submitForm () {
      this.$refs.regFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/reg', this.regForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_reg_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.regFormRef.resetFields()
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
.from-area{
  width: 40%;
}
.input-tips{
  font-size: 12px;
  color: #909399;
}
</style>
