<template>
  <div class="site-page">
    <div class="page-title">
      站点信息
    </div>
    <el-form :model="siteForm" :rules="rules" ref="siteFormRef" label-width="140px" class="from-area">
      <el-form-item label="站点名称:" prop="site_name">
        <el-input v-model="siteForm.site_name"></el-input>
        <span class="input-tips">站点名称，将显示在浏览器窗口标题等位置</span>
      </el-form-item>
      <el-form-item label="公司名称:" prop="web_name">
        <el-input v-model="siteForm.web_name"></el-input>
        <span class="input-tips">网站名称，将显示在页面底部的联系方式处</span>
      </el-form-item>
      <el-form-item label="公司地址:" prop="address">
        <el-input v-model="siteForm.address"></el-input>
        <span class="input-tips">网站名称，将显示在页面底部的联系方式处</span>
      </el-form-item>
      <el-form-item label="网站 URL:" prop="web_url">
        <el-input v-model="siteForm.web_url">
          <template slot="prepend">http://</template>
        </el-input>
        <span class="input-tips">网站 URL，将作为链接显示在页面底部</span>
      </el-form-item>
      <el-form-item label="管理员邮箱:" prop="account_mail">
        <el-input v-model="siteForm.account_mail"></el-input>
        <span class="input-tips">管理员 E-mail，将作为系统发邮件的时候的发件人地址</span>
      </el-form-item>
      <el-form-item label="联系电话:" prop="phone">
        <el-input v-model="siteForm.phone"></el-input>
      </el-form-item>
      <el-form-item label="招商代理联系电话:" prop="zsphone">
        <el-input v-model="siteForm.zsphone"></el-input>
      </el-form-item>
      <el-form-item label="QQ在线客服号码:" prop="kefu_qq">
        <el-input v-model="siteForm.kefu_qq"></el-input>
        <span class="input-tips">
          <a href="https://shang.qq.com/v3/widget.html">设置我的QQ在线状态</a>
        </span>
      </el-form-item>
      <el-form-item label="微信公众号二维码" prop="img">
        <file-pond
          name="img"
          ref="imgpond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          :server="uploadServer"
          v-bind:files="siteForm.img"
          v-on:init="handleFilePondInit"
          :chunkUploads="true"
          maxFileSize="2MB"
          :onprocessfile="ImgOnProcess"
          :onremovefile="RemoveImg"
        />
      </el-form-item>
      <el-form-item label="手机网站二维码" prop="phoneewm">
        <file-pond
          name="phoneewm"
          ref="phoneewmpond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          :server="uploadServer"
          v-bind:files="siteForm.phoneewm"
          v-on:init="handleFilePondInit"
          :chunkUploads="true"
          maxFileSize="2MB"
          :onprocessfile="PhoneEwmOnProcess"
          :onremovefile="RemovePhoneEwm"
        />
      </el-form-item>
      <el-form-item label="顶部小logo" prop="logo">
        <file-pond
          name="logo"
          ref="logopond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          :server="uploadServer"
          v-bind:files="siteForm.logo"
          v-on:init="handleFilePondInit"
          :chunkUploads="true"
          maxFileSize="2MB"
          :onprocessfile="LogoOnProcess"
          :onremovefile="RemoveLogo"
        />
      </el-form-item>
      <el-form-item label="搜索区域logo" prop="logo2">
        <file-pond
          name="logo2"
          ref="logopond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          :server="uploadServer"
          v-bind:files="siteForm.logo2"
          v-on:init="handleFilePondInit"
          :chunkUploads="true"
          maxFileSize="2MB"
          :onprocessfile="Logo2OnProcess"
          :onremovefile="RemoveLogo2"
        />
      </el-form-item>
      <el-form-item label="底部区域logo" prop="logo3">
        <file-pond
          name="logo3"
          ref="logopond"
          label-idle="Drop files here..."
          v-bind:allow-multiple="false"
          accepted-file-types="image/jpeg, image/png"
          :server="uploadServer"
          v-bind:files="siteForm.logo3"
          v-on:init="handleFilePondInit"
          :chunkUploads="true"
          maxFileSize="2MB"
          :onprocessfile="Logo3OnProcess"
          :onremovefile="RemoveLogo3"
        />
      </el-form-item>
      <el-form-item label="网站备案信息代码:" prop="web_record">
        <el-input v-model="siteForm.web_record"></el-input>
        <span class="input-tips">页面底部可以显示 ICP 备案信息，如果网站已备案，在此输入您的授权码，它将显示在页面底部，如果没有请留空</span>
      </el-form-item>
      <el-form-item label="第三方JS代码:" prop="stats_code">
        <el-input type="textarea" v-model="siteForm.stats_code"></el-input>
        <span class="input-tips">
          可填入例如百度商桥等第三方JS代码，请注意需要填写
          <code>
            script /script
          </code>
          标签
        </span>
      </el-form-item>
      <el-form-item label="关闭站点:" prop="close_site">
        <el-switch v-model="siteForm.close_site" :active-value="1" :inactive-value="0" active-color="#13ce66" inactive-color="#ff4949" active-text="关闭站点" inactive-text="开启站点">
        </el-switch>
        <span class="input-tips" style="display: block;">关闭站点后，网页将无法访问，但管理员仍可通过后台地址登录管理</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm">提交信息</el-button>
        <el-button @click="resetForm">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Site',
  data () {
    return {
      siteForm: {
        site_name: '',
        web_name: '',
        address: '',
        web_url: '',
        account_mail: '',
        phone: '',
        zsphone: '',
        kefu_qq: '',
        img: [],
        img_id: '',
        phoneewm: [],
        phoneewm_id: '',
        logo: [],
        logo_id: '',
        logo2: [],
        logo2_id: '',
        logo3: [],
        logo3_id: '',
        web_record: '',
        stats_code: '',
        close_site: 0
      },
      rules: {
        sitename: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
        ]
      },
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      },
      disabled: false
    }
  },
  created () {
    this.get_site_info()
  },
  methods: {
    // 获取站点信息
    async get_site_info () {
      const { data: res } = await this.$http.get('admin/site')
      if (res.meta.status === 200) {
        this.siteForm.site_name = res.data.site_name
        this.siteForm.web_name = res.data.web_name
        this.siteForm.address = res.data.address
        this.siteForm.web_url = res.data.web_url
        this.siteForm.account_mail = res.data.account_mail
        this.siteForm.phone = res.data.phone
        this.siteForm.zsphone = res.data.zsphone
        this.siteForm.kefu_qq = res.data.kefu_qq
        if (res.data.img) {
          this.siteForm.img = [{
            // the server file reference
            source: res.data.img,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.siteForm.img = []
        }
        this.siteForm.img_id = res.data.img_id
        if (res.data.phoneewm) {
          this.siteForm.phoneewm = [{
            // the server file reference
            source: res.data.phoneewm,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.siteForm.phoneewm = []
        }
        this.siteForm.phoneewm_id = res.data.phoneewm_id
        if (res.data.logo) {
          this.siteForm.logo = [{
            // the server file reference
            source: res.data.logo,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.siteForm.logo = []
        }
        this.siteForm.logo_id = res.data.logo_id
        if (res.data.logo2) {
          this.siteForm.logo2 = [{
            // the server file reference
            source: res.data.logo2,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.siteForm.logo2 = []
        }
        this.siteForm.logo2_id = res.data.logo2_id
        if (res.data.logo3) {
          this.siteForm.logo3 = [{
            // the server file reference
            source: res.data.logo3,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.siteForm.logo3 = []
        }
        this.siteForm.logo3_id = res.data.logo3_id
        this.siteForm.web_record = res.data.web_record
        this.siteForm.stats_code = res.data.stats_code
        this.siteForm.close_site = res.data.close_site
      }
    },
    async submitForm () {
      this.$refs.siteFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/site', this.siteForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_site_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.siteFormRef.resetFields()
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    ImgOnProcess (error, file) {
      console.log(error)
      this.siteForm.img_id = file.serverId
    },
    RemoveImg () {
      this.siteForm.img_id = ''
    },
    PhoneEwmOnProcess (error, file) {
      console.log(error)
      this.siteForm.phoneewm_id = file.serverId
    },
    RemovePhoneEwm () {
      this.siteForm.phoneewm_id = ''
    },
    LogoOnProcess (error, file) {
      console.log(error)
      this.siteForm.logo_id = file.serverId
    },
    RemoveLogo () {
      this.siteForm.logo_id = ''
      this.siteForm.logo = []
    },
    Logo2OnProcess (error, file) {
      console.log(error)
      this.siteForm.logo2_id = file.serverId
    },
    RemoveLogo2 () {
      this.siteForm.logo2_id = ''
      this.siteForm.logo = []
    },
    Logo3OnProcess (error, file) {
      console.log(error)
      this.siteForm.logo3_id = file.serverId
    },
    RemoveLogo3 () {
      this.siteForm.logo3_id = ''
      this.siteForm.logo = []
    }
  }
}
</script>

<style scoped>
  .page-title{
    font-size: 16px;
    font-weight: bold;
    color: #3a8ee6;
    border-left: 2px solid #3a8ee6;
    padding-left: 1rem;
    margin-bottom: 2rem;
  }
  .from-area{
    width: 40%;
  }
  .input-tips{
    font-size: 12px;
    color: #909399;
  }
</style>
