<template>
  <div class="reviews-page">
    <div class="page-title">
      评论管理
    </div>
    <div class="page-content">
      <div class="reviews-list">
        <el-row :gutter="20" style="padding: 1rem 0">
          <el-col :span="6">
            <el-input placeholder="请输入问题标题" v-model="queryInfo.seachwords" clearable @clear="getReviewsList">
              <el-button slot="append" icon="el-icon-search" @click="getReviewsList"></el-button>
            </el-input>
          </el-col>
        </el-row>
        <!--用户列表回答-->
        <el-table :data="reviewsList" style="width: 100%" border stripe>
          <el-table-column prop="id" label="ID" width="60px"></el-table-column>
          <el-table-column prop="title" label="问题标题"></el-table-column>
          <el-table-column prop="answer" label="回答内容"></el-table-column>
          <el-table-column prop="answer_user" label="回答用户"></el-table-column>
          <el-table-column prop="creat_time" label="回答时间"></el-table-column>
          <el-table-column label="操作" width="250px">
            <template slot-scope="scope">
              <el-tooltip content="删除" placement="top" :enterable="false">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row.id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--分页问题-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[5, 10, 50, 100]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryInfo.total" style="padding: 1rem 0;text-align: center">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Reviews',
  data () {
    return {
      reviewsList: [],
      // 获取回答列表的参数对象
      queryInfo: {
        // 搜索
        seachwords: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 5,
        // 总数据条数
        total: 0
      }
    }
  },
  created () {
    this.getReviewsList()
  },
  methods: {
    async getReviewsList () {
      const { data: res } = await this.$http.get('/admin/reviews-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.reviewsList = res.data.answer_list
        this.queryInfo.total = res.data.totalpage
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听 每页显示多少改变事件
    handleSizeChange (newsize) {
      this.queryInfo.pagesize = newsize
      this.getReviewsList()
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getReviewsList()
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('admin/delete-reviews/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.getReviewsList()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    dateFormat: function (row, column) {
      var date = row[column.property]
      if (date === undefined || date === null) {
        return ''
      }
      return this.$moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
