<template>
  <div class="zt-fj-page">
    <div class="page-title">
      展厅附件管理
    </div>
    <div class="page-body">
      <div class="fj-list">
        <el-row :gutter="20" style="padding: 1rem 0">
          <el-col :span="6">
            <el-input placeholder="请输入附件名称" v-model="queryInfo.seachwords" clearable @clear="getFjList">
              <el-button slot="append" icon="el-icon-search" @click="getFjList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4" :offset="14" style="text-align: right">
            <el-button type="primary" @click="addItemVisible = true">添加附件</el-button>
          </el-col>
        </el-row>
        <!--附件列表区域-->
        <el-table :data="fjList" style="width: 100%" border stripe>
          <el-table-column prop="fj_id" label="ID" width="60px"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="tid" label="归属展厅"></el-table-column>
          <el-table-column prop="name" label="附件名称"></el-table-column>
          <el-table-column prop="downloads" label="下载次数"></el-table-column>
          <el-table-column label="操作" width="250px">
            <template slot-scope="scope">
              <el-tooltip content="编辑" placement="top" :enterable="false">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="editItem(scope.row.fj_id)"></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" :enterable="false">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row.fj_id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--分页区域-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[5, 10, 50, 100]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryInfo.total" style="padding: 1rem 0;text-align: center">
        </el-pagination>
      </div>
      <!--添加附件对话框-->
      <el-dialog :close-on-click-modal="false" title="添加附件" :visible.sync="addItemVisible" width="50%" @close="closeAddFrom">
        <!--内容主体区域-->
        <el-form :model="addForm" :rules="addRules" ref="addRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="addForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属展厅" prop="tid">
            <el-select v-model="addForm.tid" placeholder="请选择">
              <el-option
                v-for="item in ztList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附件名称" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="附件" prop="source">
            <file-pond
              name="source"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              :server="uploadServer"
              v-bind:files="addForm.source"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="addSourceonprocess"
              :onremovefile="addRemoveSource"
            />
          </el-form-item>
        </el-form>
        <!--底部按钮区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addItemVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <!--编辑附件对话框-->
      <el-dialog :close-on-click-modal="false" title="编辑附件" :visible.sync="editItemVisible" width="50%" @close="closeEditFrom">
        <!--内容主体区域-->
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="editForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属展厅" prop="tid">
            <el-select v-model="editForm.tid" placeholder="请选择">
              <el-option
                v-for="item in ztList"
                :key="item.zt_id"
                :label="item.name"
                :value="item.zt_id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="附件名称" prop="name">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="附件" prop="source">
            <file-pond
              name="source"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              :server="uploadServer"
              v-bind:files="editForm.source"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="editSourceonprocess"
              :onremovefile="editRemoveSource"
            />
          </el-form-item>
        </el-form>
        <!--底部按钮区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editItemVisible = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Fj',
  data () {
    return {
      fjList: [],
      // 获取展厅列表的参数对象
      queryInfo: {
        // 搜索
        seachwords: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 5,
        // 总数据条数
        total: 0
      },
      addItemVisible: false,
      ztList: [],
      addForm: {
        sort: '',
        tid: '',
        name: '',
        source: [],
        source_id: ''
      },
      // 添加表单的验证规则对象
      addRules: {
        tid: [
          { required: true, message: '请选择归属展厅', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入附件名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      },
      editItemVisible: false,
      editForm: {
        fj_id: '',
        sort: '',
        tid: '',
        name: '',
        source: [],
        source_id: ''
      },
      // 修改表单的验证规则对象
      editRules: {
        tid: [
          { required: true, message: '请选择归属展厅', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入附件名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      },
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      },
      disabled: false
    }
  },
  created () {
    this.getFjList()
    this.getZtList()
  },
  methods: {
    async getFjList () {
      const { data: res } = await this.$http.get('admin/fj-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.fjList = res.data.fj_list
        this.queryInfo.total = res.data.totalpage
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async getZtList () {
      const { data: res } = await this.$http.get('admin/zt-list')
      if (res.meta.status === 200) {
        this.ztList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听 每页显示多少改变事件
    handleSizeChange (newsize) {
      this.queryInfo.pagesize = newsize
      this.getFjList()
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getFjList()
    },
    async addSubmit () {
      const { data: res } = await this.$http.post('admin/fj/1', this.addForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.addRef.resetFields()
        this.getFjList()
        this.addItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async editSubmit () {
      const { data: res } = await this.$http.put('admin/fj/' + this.editForm.fj_id, this.editForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.editRef.resetFields()
        this.getFjList()
        this.editItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    addImgonprocess (error, file) {
      console.log(error)
      this.addForm.img_id = file.serverId
    },
    addSourceonprocess (error, file) {
      console.log(error)
      this.addForm.source_id = file.serverId
    },
    addRemoveImg () {
      this.addForm.img_id = ''
    },
    addRemoveSource () {
      this.addForm.source_id = ''
    },
    editImgonprocess (error, file) {
      console.log(error)
      this.editForm.img_id = file.serverId
    },
    editSourceonprocess (error, file) {
      console.log(error)
      this.editForm.source_id = file.serverId
    },
    editRemoveImg () {
      this.editForm.img_id = ''
      this.editForm.img = []
    },
    editRemoveSource () {
      this.editForm.source_id = ''
      this.editForm.source = []
    },
    // 监听添加用户对话框关闭事件
    closeAddFrom () {
      this.$refs.addRef.resetFields()
    },
    // 监听编辑用户对话框关闭事件
    closeEditFrom () {
      this.$refs.editRef.resetFields()
    },
    async editItem (id) {
      const { data: res } = await this.$http.get('admin/fj/' + id)
      if (res.meta.status === 200) {
        this.editForm.fj_id = id
        this.editForm.sort = res.data.sort
        this.editForm.tid = res.data.tid
        this.editForm.name = res.data.name
        this.editForm.enname = res.data.enname
        this.editForm.jpname = res.data.jpname
        if (res.data.img) {
          this.editForm.img = [{
            // the server file reference
            source: res.data.img,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.img
              }
            }
          }]
        } else {
          this.editForm.img = []
        }
        this.editForm.img_id = res.data.img_id
        if (res.data.source) {
          this.editForm.source = [{
            // the server file reference
            source: res.data.source,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.source
              }
            }
          }]
        } else {
          this.editForm.source = []
        }
        this.editForm.source_id = res.data.source_id
        this.editItemVisible = true
      }
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('admin/fj/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.getFjList()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    dateFormat: function (row, column) {
      var date = row[column.property]
      if (date === undefined || date === null) {
        return ''
      }
      return this.$moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped>

</style>
