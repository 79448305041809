<template>
  <div class="zg-page">
    <div class="page-title">
      展馆管理
    </div>
    <div class="page-body">
      <div class="zg-list">
        <el-row :gutter="20" style="padding: 1rem 0">
          <el-col :span="6">
            <el-input placeholder="请输入展馆名称" v-model="queryInfo.seachwords" clearable @clear="getZgList">
              <el-button slot="append" icon="el-icon-search" @click="getZgList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4" :offset="14" style="text-align: right">
            <el-button type="primary" @click="addItemVisible = true">添加展馆</el-button>
          </el-col>
        </el-row>
        <!--展馆列表区域-->
        <el-table :data="zgList" style="width: 100%" border stripe>
          <el-table-column prop="id" label="ID" width="60px"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="belong" label="归属展会"></el-table-column>
          <el-table-column prop="name" label="展馆名称"></el-table-column>
          <el-table-column prop="start_time" label="开始时间"></el-table-column>
          <el-table-column prop="end_time" label="结束时间"></el-table-column>
          <el-table-column prop="password" label="密码访问"></el-table-column>
          <el-table-column prop="login" label="是否需要登录"></el-table-column>
          <el-table-column prop="uid" label="归属帐号"></el-table-column>
          <el-table-column prop="view" label="浏览次数"></el-table-column>
          <el-table-column label="操作" width="250px">
            <template slot-scope="scope">
              <el-tooltip content="编辑" placement="top" :enterable="false">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="editItem(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" :enterable="false">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row.id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--分页区域-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[5, 10, 50, 100]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryInfo.total" style="padding: 1rem 0;text-align: center">
        </el-pagination>
      </div>
      <!--添加展馆对话框-->
      <el-dialog :close-on-click-modal="false" title="添加展馆" :visible.sync="addItemVisible" width="50%" @close="closeAddFrom">
        <!--内容主体区域-->
        <el-form :model="addForm" :rules="addRules" ref="addRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="addForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属展会" prop="tid">
            <el-select v-model="addForm.tid" placeholder="请选择">
              <el-option
                v-for="item in zlhList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="展馆名称" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="展馆缩略图" prop="img">
            <file-pond
              name="img"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="addForm.img"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="addImgonprocess"
              :onremovefile="addRemoveImg"
            />
          </el-form-item>
          <el-form-item label="展馆导航图" prop="nav_img">
            <file-pond
              name="nav_img"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="addForm.nav_img"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="addNavimgonprocess"
              :onremovefile="addRemoveNav"
            />
          </el-form-item>
          <el-form-item label="展馆地址区域" prop="area">
            <el-cascader v-model="addForm.area" :props="optionProps" :options="locList" @change="handleAddChange" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="展馆地址" prop="address">
            <el-input v-model="addForm.address"></el-input>
          </el-form-item>
          <el-form-item label="展馆坐标系" prop="coordinate">
            <el-input v-model="addForm.coordinate"></el-input>
            <el-link type="warning" href="http://api.map.baidu.com/lbsapi/getpoint/index.html" :underline="false" target="_blank">点击拾取坐标</el-link>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-cascader v-model="addForm.industry" :props="industryProps" :options="industry" @change="handleAddChange" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="展馆时间段" prop="timerange">
            <el-date-picker v-model="addForm.timerange" type="datetimerange" align="right" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="主办方名称" prop="sponsor">
            <el-input v-model="addForm.sponsor"></el-input>
          </el-form-item>
          <el-form-item label="主办方联系方式" prop="sponsor_phone">
            <el-input v-model="addForm.sponsor_phone"></el-input>
          </el-form-item>
          <el-form-item label="主办方客服二维码" prop="sponsor_ewm">
            <file-pond
              name="sponsor_ewm"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="addForm.sponsor_ewm"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="addSponsorprocess"
              :onremovefile="addRemoveSponsor"
            />
          </el-form-item>
          <el-form-item label="展馆VR链接" prop="vr_link">
            <el-input v-model="addForm.vr_link"></el-input>
          </el-form-item>
          <el-form-item label="展馆直播链接" prop="zb_link">
            <el-input v-model="addForm.zb_link"></el-input>
          </el-form-item>
          <el-form-item label="展馆互动链接" prop="hd_link">
            <el-input v-model="addForm.hd_link"></el-input>
          </el-form-item>
          <el-form-item label="访问密码" prop="password">
            <el-input v-model="addForm.password"></el-input>
          </el-form-item>
          <el-form-item label="直播密码" prop="zb_password">
            <el-input v-model="addForm.zb_password"></el-input>
          </el-form-item>
          <el-form-item label="互动密码" prop="hd_password">
            <el-input v-model="addForm.hd_password"></el-input>
          </el-form-item>
          <el-form-item label="展馆介绍" prop="introduce">
            <ckeditor :editor="editor" @ready="onReady" v-model="addForm.introduce" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="日程安排" prop="schedule">
            <ckeditor :editor="editor" @ready="onReady" v-model="addForm.schedule" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="参展须知" prop="note">
            <ckeditor :editor="editor" @ready="onReady" v-model="addForm.note" :config="editorConfig"></ckeditor>
          </el-form-item>
        </el-form>
        <!--底部按钮区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="addItemVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <!--编辑展馆对话框-->
      <el-dialog :close-on-click-modal="false" title="编辑展馆" :visible.sync="editItemVisible" width="50%" @close="closeEditFrom">
        <!--内容主体区域-->
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="editForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属展会" prop="tid">
            <el-select v-model="editForm.tid" placeholder="请选择">
              <el-option
                v-for="item in zlhList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="展馆名称" prop="name">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="展馆缩略图" prop="img">
            <file-pond
              name="img"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="editForm.img"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="editImgonprocess"
              :onremovefile="editRemoveImg"
            />
          </el-form-item>
          <el-form-item label="展馆导航图" prop="nav_img">
            <file-pond
              name="nav_img"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="editForm.nav_img"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="editNavimgonprocess"
              :onremovefile="editRemoveNav"
            />
          </el-form-item>
          <el-form-item label="展馆地址区域" prop="area">
            <el-cascader v-model="editForm.area" :props="optionProps" :options="locList" @change="handleAddChange" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="展馆地址" prop="address">
            <el-input v-model="editForm.address"></el-input>
          </el-form-item>
          <el-form-item label="展馆坐标系" prop="coordinate">
            <el-input v-model="editForm.coordinate"></el-input>
            <el-link type="warning" href="http://api.map.baidu.com/lbsapi/getpoint/index.html" :underline="false" target="_blank">点击拾取坐标</el-link>
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-cascader v-model="editForm.industry" :props="industryProps" :options="industry" @change="handleAddChange" clearable></el-cascader>
          </el-form-item>
          <el-form-item label="展馆时间段" prop="timerange">
            <el-date-picker v-model="editForm.timerange" type="datetimerange" align="right" start-placeholder="开始日期" end-placeholder="结束日期" style="width: 100%">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="主办方名称" prop="sponsor">
            <el-input v-model="editForm.sponsor"></el-input>
          </el-form-item>
          <el-form-item label="主办方联系方式" prop="sponsor_phone">
            <el-input v-model="editForm.sponsor_phone"></el-input>
          </el-form-item>
          <el-form-item label="主办方客服二维码" prop="sponsor_ewm">
            <file-pond
              name="sponsor_ewm"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="editForm.sponsor_ewm"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="editSponsorprocess"
              :onremovefile="editRemoveSponsor"
            />
          </el-form-item>
          <el-form-item label="展馆VR链接" prop="vr_link">
            <el-input v-model="editForm.vr_link"></el-input>
          </el-form-item>
          <el-form-item label="展馆直播链接" prop="zb_link">
            <el-input v-model="editForm.zb_link"></el-input>
          </el-form-item>
          <el-form-item label="展馆互动链接" prop="hd_link">
            <el-input v-model="editForm.hd_link"></el-input>
          </el-form-item>
          <el-form-item label="访问密码" prop="password">
            <el-input v-model="editForm.password"></el-input>
          </el-form-item>
          <el-form-item label="直播密码" prop="zb_password">
            <el-input v-model="editForm.zb_password"></el-input>
          </el-form-item>
          <el-form-item label="互动密码" prop="hd_password">
            <el-input v-model="editForm.hd_password"></el-input>
          </el-form-item>
          <el-form-item label="展馆介绍" prop="introduce">
            <ckeditor :editor="editor" @ready="onReady" v-model="editForm.introduce" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="日程安排" prop="schedule">
            <ckeditor :editor="editor" @ready="onReady" v-model="editForm.schedule" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="参展须知" prop="note">
            <ckeditor :editor="editor" @ready="onReady" v-model="editForm.note" :config="editorConfig"></ckeditor>
          </el-form-item>
        </el-form>
        <!--底部按钮区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editItemVisible = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import MyUploadAdapter from '@/assets/js/MyUploadAdapter'
import locList from '../../assets/js/LocList.json'
import industry from '../../assets/js/industry.json'
export default {
  name: 'Zg',
  data () {
    return {
      optionProps: {
        value: 'label',
        label: 'label',
        children: 'children'
      },
      industryProps: {
        value: 'name',
        label: 'name',
        children: 'children'
      },
      locList: locList,
      industry: industry,
      zgList: [],
      // 获取展会列表的参数对象
      queryInfo: {
        // 搜索
        seachwords: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 5,
        // 总数据条数
        total: 0
      },
      addItemVisible: false,
      zlhList: [],
      addForm: {
        sort: '',
        tid: '',
        name: '',
        img: [],
        img_id: '',
        nav_img: [],
        nav_img_id: '',
        area: [],
        address: '',
        coordinate: '',
        industry: [],
        timerange: '',
        sponsor: '',
        sponsor_phone: '',
        sponsor_ewm: [],
        sponsor_ewm_id: '',
        vr_link: '',
        zb_link: '',
        hd_link: '',
        password: '',
        zb_password: '',
        hd_password: '',
        introduce: '',
        schedule: '',
        note: ''
      },
      // 添加表单的验证规则对象
      addRules: {
        belong: [
          { required: true, message: '请选择归属展会', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入展馆名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      },
      editItemVisible: false,
      editForm: {
        id: '',
        sort: '',
        tid: '',
        name: '',
        img: [],
        img_id: '',
        nav_img: [],
        nav_img_id: '',
        area: [],
        address: '',
        coordinate: '',
        industry: [],
        timerange: '',
        sponsor: '',
        sponsor_phone: '',
        sponsor_ewm: [],
        sponsor_ewm_id: '',
        vr_link: '',
        zb_link: '',
        hd_link: '',
        password: '',
        zb_password: '',
        hd_password: '',
        introduce: '',
        schedule: '',
        note: ''
      },
      // 修改表单的验证规则对象
      editRules: {
        belong: [
          { required: true, message: '请选择归属展会', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入展馆名称', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      },
      uploadSeting: {
        multiple: false, // 是否可以多选文件
        action: axios.defaults.baseURL + 'upload',
        auto_upload: true, // 是否立即上传
        limit: 1, // 允许的最大上传数量
        // 图片上传请求头
        headers: {
          Authorization: window.sessionStorage.getItem('token'),
          username: window.sessionStorage.getItem('username')
        }
      },
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      },
      disabled: false,
      editor: DecoupledEditor,
      // ckeditor 配置
      editorConfig: {
        // Run the editor with the German UI.
        toolbar: {
          items: [
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            '|',
            'bold',
            'highlight',
            'fontColor',
            'fontBackgroundColor',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'alignment',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            'link',
            'blockQuote',
            'horizontalLine',
            'imageUpload',
            'insertTable',
            '|',
            'exportWord',
            'exportPdf',
            'undo',
            'redo'
          ]
        },
        language: 'zh-cn',
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:full',
            'imageStyle:side'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
          ]
        }
      }
    }
  },
  created () {
    this.getZgList()
    this.getZlhList()
  },
  methods: {
    handleAddChange (value) {
      console.log(value)
    },
    async getZgList () {
      const { data: res } = await this.$http.get('admin/zg-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.zgList = res.data.zg_list
        this.queryInfo.total = res.data.totalpage
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async getZlhList () {
      const { data: res } = await this.$http.get('admin/zlh-list')
      if (res.meta.status === 200) {
        this.zlhList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听 每页显示多少改变事件
    handleSizeChange (newsize) {
      this.queryInfo.pagesize = newsize
      this.getZgList()
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getZgList()
    },
    async addSubmit () {
      const { data: res } = await this.$http.post('admin/zg/1', this.addForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.addRef.resetFields()
        this.getZgList()
        this.addItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async editSubmit () {
      const { data: res } = await this.$http.put('admin/zg/' + this.editForm.id, this.editForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.editRef.resetFields()
        this.getZgList()
        this.editItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    addImgonprocess (error, file) {
      console.log(error)
      this.addForm.img_id = file.serverId
    },
    addNavimgonprocess (error, file) {
      console.log(error)
      this.addForm.nav_img_id = file.serverId
    },
    addSponsorprocess (error, file) {
      console.log(error)
      this.addForm.sponsor_ewm_id = file.serverId
    },
    addRemoveImg () {
      this.addForm.img_id = ''
    },
    addRemoveNav () {
      this.addForm.nav_img_id = ''
    },
    addRemoveSponsor () {
      this.addForm.sponsor_ewm_id = ''
    },
    editImgonprocess (error, file) {
      console.log(error)
      this.editForm.img_id = file.serverId
    },
    editNavimgonprocess (error, file) {
      console.log(error)
      this.editForm.nav_img_id = file.serverId
    },
    editSponsorprocess (error, file) {
      console.log(error)
      this.editForm.sponsor_ewm_id = file.serverId
    },
    editRemoveImg () {
      this.editForm.img_id = ''
      this.editForm.img = []
    },
    editRemoveNav () {
      this.editForm.nav_img_id = ''
      this.editForm.nav_img = []
    },
    editRemoveSponsor () {
      this.editForm.sponsor_ewm_id = ''
      this.editForm.sponsor_ewm = []
    },
    // 监听添加用户对话框关闭事件
    closeAddFrom () {
      this.$refs.addRef.resetFields()
    },
    // 监听编辑用户对话框关闭事件
    closeEditFrom () {
      this.$refs.editRef.resetFields()
    },
    async editItem (id) {
      const { data: res } = await this.$http.get('admin/zg/' + id)
      if (res.meta.status === 200) {
        this.editForm.id = id
        this.editForm.sort = res.data.sort
        this.editForm.tid = res.data.tid
        this.editForm.name = res.data.name
        if (res.data.img) {
          this.editForm.img = [{
            // the server file reference
            source: res.data.img,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.img
              }
            }
          }]
        } else {
          this.editForm.img = []
        }
        this.editForm.img_id = res.data.img_id
        if (res.data.nav_img) {
          this.editForm.nav_img = [{
            // the server file reference
            source: res.data.nav_img,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.nav_img
              }
            }
          }]
        } else {
          this.editForm.nav_img = []
        }
        this.editForm.nav_img_id = res.data.nav_img_id
        this.editForm.area = res.data.area
        this.editForm.address = res.data.address
        this.editForm.coordinate = res.data.coordinate
        this.editForm.industry = res.data.industry
        this.editForm.timerange = [res.data.start_time, res.data.end_time]
        this.editForm.sponsor = res.data.sponsor
        this.editForm.sponsor_phone = res.data.sponsor_phone
        if (res.data.sponsor_ewm) {
          this.editForm.sponsor_ewm = [{
            // the server file reference
            source: res.data.sponsor_ewm,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.sponsor_ewm
              }
            }
          }]
        } else {
          this.editForm.sponsor_ewm = []
        }
        this.editForm.sponsor_ewm_id = res.data.sponsor_ewm_id
        this.editForm.vr_link = res.data.vr_link
        this.editForm.zb_link = res.data.zb_link
        this.editForm.hd_link = res.data.hd_link
        this.editForm.password = res.data.password
        this.editForm.zb_password = res.data.zb_password
        this.editForm.hd_password = res.data.hd_password
        this.editForm.introduce = res.data.introduce
        this.editForm.schedule = res.data.schedule
        this.editForm.note = res.data.note
        this.$forceUpdate()
        this.editItemVisible = true
      }
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('admin/zg/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.getZgList()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    dateFormat: function (row, column) {
      var date = row[column.property]
      if (date === undefined || date === null) {
        return ''
      }
      return this.$moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    },
    onReady (editor) {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      )
      editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new MyUploadAdapter(loader)
      }
    }
  }
}
</script>

<style scoped>

</style>
