import { render, staticRenderFns } from "./Pagenav.vue?vue&type=template&id=7240b845&scoped=true&"
import script from "./Pagenav.vue?vue&type=script&lang=js&"
export * from "./Pagenav.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7240b845",
  null
  
)

export default component.exports