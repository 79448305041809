<template>
  <div class="header-page">
    <div class="logo">
      <img src="../../assets/images/logo.png"/>
    </div>
    <el-menu :default-active="activeIndex" class="header-menu-nav" mode="horizontal">
      <el-menu-item index="1" @click="fullscreen">
        <i class="icon iconfont icon-viewfinder"></i>
      </el-menu-item>
      <el-submenu index="2">
        <template slot="title">
          <img class="user-img" src="../../assets/images/user.gif"/>
          admin
        </template>
        <el-menu-item index="2-2" @click="change_password">修改密码</el-menu-item>
        <el-menu-item index="2-3" @click="logout">退出登录</el-menu-item>
      </el-submenu>
    </el-menu>
    <el-dialog title="修改密码" :visible.sync="changePdVisible" width="30%" :before-close="chanPdClose">
      <el-input placeholder="请输入新密码" v-model="newPassword" clearable></el-input>
      <el-input placeholder="请再次输入新密码" v-model="confignewPassword" clearable></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="changePdVisible = false">取 消</el-button>
        <el-button type="primary" @click="putNewPd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import screenfull from 'screenfull'
export default {
  name: 'Header',
  data () {
    return {
      activeIndex: '0',
      // 修改密码模态框显示状态
      changePdVisible: false,
      // 新密码
      newPassword: '',
      confignewPassword: ''
    }
  },
  methods: {
    // 全屏切换
    fullscreen () {
      if (!screenfull.isEnabled) {
        this.$message.error('您的浏览器不支持全屏显示')
        return false
      }
      screenfull.toggle()
    },
    // 修改密码
    change_password () {
      this.changePdVisible = true
    },
    // 关闭修改密码模态框
    chanPdClose (done) {
      this.changePdVisible = false
    },
    // 确认修改新密码
    async putNewPd () {
      if (this.newPassword !== this.confignewPassword) {
        this.$message.error('两次密码输入不一致，请重新输入')
      } else if (this.newPassword.length < 6 || this.newPassword.length > 20) {
        this.$message.error('密码最少6位，最多20位字符')
      } else {
        const { data: res } = await this.$http.put('/admin/change-password', { newpassword: this.newPassword })
        if (res.meta.status === 200) {
          this.$message.success('修改密码成功！')
          this.changePdVisible = false
        } else {
          this.$message.error(res.meta.msg)
        }
      }
    },
    // 退出登录
    logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped>
.header-page{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-page .logo{
  height: 60px;
}
.header-page .logo img{
  height: 60px;
}
.header-page .header-menu-nav{}
.user-img{
  height: 60px;
}
</style>
