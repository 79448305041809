<template>
  <div class="ad-page">
    <div class="page-title">
      广告投放
    </div>
    <div class="page-content">
      <el-form :model="ad" :rules="rules" ref="adFormRef" label-width="200px" class="ad-area">
        <el-form-item label="广告1" prop="ad1">
          <file-pond
            name="ad1"
            ref="imgpond"
            label-idle="Drop files here..."
            v-bind:allow-multiple="false"
            accepted-file-types="image/jpeg, image/png"
            :server="uploadServer"
            v-bind:files="ad.ad1"
            v-on:init="handleFilePondInit"
            :chunkUploads="true"
            maxFileSize="2MB"
            :onprocessfile="Ad1OnProcess"
            :onremovefile="RemoveAd1"
          />
        </el-form-item>
        <el-form-item label="广告1链接:" prop="link1">
          <el-input v-model="ad.link1"></el-input>
        </el-form-item>
        <el-form-item label="广告2" prop="ad2">
          <file-pond
            name="ad2"
            ref="imgpond"
            label-idle="Drop files here..."
            v-bind:allow-multiple="false"
            accepted-file-types="image/jpeg, image/png"
            :server="uploadServer"
            v-bind:files="ad.ad2"
            v-on:init="handleFilePondInit"
            :chunkUploads="true"
            maxFileSize="2MB"
            :onprocessfile="Ad2OnProcess"
            :onremovefile="RemoveAd2"
          />
        </el-form-item>
        <el-form-item label="广告2链接:" prop="link2">
          <el-input v-model="ad.link2"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitAdForm">提交信息</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'Ad',
  data () {
    return {
      ad: {
        ad1: [],
        ad1_id: '',
        link1: '',
        ad2: [],
        ad2_id: '',
        link2: ''
      },
      rules: {},
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      },
      disabled: false
    }
  },
  created () {
    this.get_ad_info()
  },
  methods: {
    async get_ad_info () {
      const { data: res } = await this.$http.get('admin/ad-setting')
      if (res.meta.status === 200) {
        if (res.data.ad1) {
          this.ad.ad1 = [{
            // the server file reference
            source: res.data.ad1,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.ad.ad1 = []
        }
        this.ad.ad1_id = res.data.ad1_id
        this.ad.link1 = res.data.link1
        if (res.data.ad2) {
          this.ad.ad2 = [{
            // the server file reference
            source: res.data.ad2,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local'
            }
          }]
        } else {
          this.ad.ad2 = []
        }
        this.ad.ad2_id = res.data.ad2_id
        this.ad.link2 = res.data.link2
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async submitAdForm () {
      this.$refs.adFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/ad-setting', this.ad)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_ad_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    Ad1OnProcess (error, file) {
      console.log(error)
      this.ad.ad1_id = file.serverId
    },
    RemoveAd1 () {
      this.ad.ad1_id = ''
    },
    Ad2OnProcess (error, file) {
      console.log(error)
      this.ad.ad2_id = file.serverId
    },
    RemoveAd2 () {
      this.ad.ad2_id = ''
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
