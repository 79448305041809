import Vue from 'vue'

// Element UI
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// Iconfont
import './assets/fonts/iconfont.css'

// Axios
import axios from 'axios'

// Import Vue FilePond
import vueFilePond from 'vue-filepond'
// Import FilePond styles
import 'filepond/dist/filepond.min.css'
// Import image preview plugin styles
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginImageResize from 'filepond-plugin-image-resize'
import FilePondPluginImageCrop from 'filepond-plugin-image-crop'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import FilePondPluginFileMetadata from 'filepond-plugin-file-metadata'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImageTransform from 'filepond-plugin-image-transform'
import moment from 'moment'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import VueQrcode from '@chenfengyuan/vue-qrcode'
import VueClipboard from 'vue-clipboard2'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.use(ElementUI)
const FilePond = vueFilePond(FilePondPluginImageTransform, FilePondPluginImageExifOrientation, FilePondPluginImageCrop, FilePondPluginFileValidateType, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileMetadata, FilePondPluginImageResize)

Vue.prototype.$FilePond = FilePond

Vue.component(VueQrcode.name, VueQrcode)
Vue.use(VueClipboard)
Vue.use(CKEditor)
Vue.prototype.$moment = moment

// 配置axios根据不同环境发起不同请求域名
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://a.com'
} else if (process.env.NODE_ENV === 'debug') {
  axios.defaults.baseURL = 'http://a.com'
} else if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://vrapi.gljshy.com'
}

axios.defaults.timeout = 10000 // 超时时间

// 通过axios请求拦截器，为请求头添加token, 以保证能有权限获取服务器数据
axios.interceptors.request.use(function (config) {
  config.headers.Authorization = store.state.userInfo.token
  config.headers.common.username = store.state.userInfo.username
  return config
},
error => {
  this.$message.error('请求超时，请稍后重试！')
  return Promise.reject(error)
})

// axios 响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  if (response.status === 200) {
    // 10010 是token验证失败错误码
    if (response.data.meta.status === 10010) {
      router.push('/login')
    }
    return response
  } else {
    ElementUI.Message.error('网络错误！')
  }
},
error => {
  ElementUI.Message.error('请求失败，请稍后重试！')
  return Promise.reject(error)
})

Vue.prototype.$http = axios

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
