<template>
  <div class="tourist-page">
    <div class="page-title">
      游客管理
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tourist'
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
