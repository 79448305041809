import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/components/Login'
import Home from '@/components/Home'
import Welcome from '@/components/welcome/Welcome'
import Site from '@/components/quanjupeizhi/Site'
import Reg from '@/components/quanjupeizhi/Reg'
import Ability from '@/components/quanjupeizhi/Ability'
import Jurisdiction from '@/components/quanjupeizhi/Jurisdiction'
import Integral from '@/components/quanjupeizhi/Integral'
import Upload from '@/components/quanjupeizhi/Upload'
import Pagenav from '@/components/jiemianshezhi/Pagenav'
import Typesetting from '@/components/jiemianshezhi/Typesetting'
import WikiSort from '@/components/neirongguanli/WikiSort'
import Wiki from '@/components/neirongguanli/Wiki'
import Question from '@/components/neirongguanli/Question'
import Special from '@/components/neirongguanli/Special'
import Article from '@/components/neirongguanli/Article'
import Reviews from '@/components/neirongguanli/Reviews'
import Other from '@/components/neirongguanli/Other'
import Member from '@/components/yonghuguanli/Member'
import Tourist from '@/components/yonghuguanli/Tourist'
import Identification from '@/components/yunying/Identification'
import Verification from '@/components/yunying/Verification'
import Announcements from '@/components/yunying/Announcements'
import Linkssort from '@/components/yunying/Linkssort'
import Links from '@/components/yunying/Links'
import Ad from '@/components/yunying/Ad'
import Role from '@/components/quanxianguanli/Role'
import Administrators from '@/components/quanxianguanli/Administrators'
import Area from '@/components/neirongguanli/Area'
import Zlh from '@/components/neirongguanli/Zlh'
import Zg from '@/components/neirongguanli/Zg'
import Zt from '@/components/neirongguanli/Zt'
import Fj from '@/components/neirongguanli/Fj'
import Shop from '@/components/neirongguanli/Shop'
import Banner from '@/components/jiemianshezhi/Banner'
import Brand from '@/components/yunying/Brand'
Vue.use(VueRouter)

const routes = [
  { path: '/', redirect: '/login' },
  { path: '/login', component: Login },
  {
    path: '/home',
    component: Home,
    children: [
      // 系统欢迎页面
      { path: '/welcome', component: Welcome },
      // 站点设置页面
      { path: '/site', component: Site },
      // 注册与访问
      { path: '/reg', component: Reg },
      // 站点功能
      { path: '/ability', component: Ability },
      // 用户权限
      { path: '/jurisdiction', component: Jurisdiction },
      // 积分设置
      { path: '/integral', component: Integral },
      // 上传设置
      { path: '/upload', component: Upload },
      // 页面导航
      { path: '/page-nav', component: Pagenav },
      // 界面设置
      { path: '/typesetting', component: Typesetting },
      // 滚动图管理
      { path: '/banner', component: Banner },
      // 百科分类管理
      { path: '/wiki-sort', component: WikiSort },
      // 百科文章管理
      { path: '/wiki', component: Wiki },
      // 专题管理
      { path: '/special', component: Special },
      // 文章管理
      { path: '/article', component: Article },
      // 评论管理
      { path: '/reviews', component: Reviews },
      // 问题管理
      { path: '/question', component: Question },
      // 其他设置
      { path: '/other', component: Other },
      // 会员管理
      { path: '/member', component: Member },
      // 游客管理
      { path: '/tourist', component: Tourist },
      // 内容鉴定
      { path: '/identification', component: Identification },
      // 验证设置
      { path: '/verification', component: Verification },
      // 公告管理
      { path: '/announcements', component: Announcements },
      // 友情链接分类
      { path: '/linkssort', component: Linkssort },
      // 友情链接
      { path: '/links', component: Links },
      // 广告投放
      { path: '/ad', component: Ad },
      // 角色管理
      { path: '/role', component: Role },
      // 管理员管理
      { path: '/administrators', component: Administrators },
      // 区域管理
      { path: '/area', component: Area },
      // 展览会管理
      { path: '/zlh', component: Zlh },
      // 展馆管理
      { path: '/zg', component: Zg },
      // 展厅管理
      { path: '/zt', component: Zt },
      // 展厅附件管理
      { path: '/fj', component: Fj },
      // 展厅商品管理
      { path: '/shop', component: Shop },
      // 品牌合作商
      { path: '/brand', component: Brand }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
