<template>
  <div class="role-page">
    <div class="page-title">
      角色管理
    </div>
    <div class="page-body">
      <div class="menu-btn-list" style="padding: 1rem 0;">
        <div class="item">
          <el-button type="primary" plain @click="addRole">添加新角色</el-button>
        </div>
      </div>
      <el-table :data="roleList" style="width: 100%;margin-bottom: 20px;" row-key="id" border default-expand-all>
        <el-table-column prop="id" label="#ID" sortable width="100"></el-table-column>
        <el-table-column prop="name" label="角色名称"></el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="添加新角色" :visible.sync="addRoleVisible" :close-on-click-modal="false" width="30%" center>
        <el-form :model="addNewRoleFrom" :rules="addNewRoleRules" ref="addRoleForm" label-width="auto" class="addNewRoleFrom">
          <el-form-item label="权限组名称" prop="role_name">
            <el-input placeholder="请输入权限组名称" v-model="addNewRoleFrom.role_name" clearable></el-input>
          </el-form-item>
          <el-form-item label="权限" prop="role">
            <el-tree :data="allMenus" ref="addtree" show-checkbox default-expand-all node-key="id" highlight-current :props="{label: 'name', children: 'children'}"></el-tree>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addRoleVisible = false">取 消</el-button>
          <el-button type="primary" @click="addRoleSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="修改角色" :visible.sync="editRoleVisible" :close-on-click-modal="false" width="30%" center>
        <el-form :model="editNewRoleFrom" :rules="editNewRoleRules" ref="editRoleForm" label-width="auto" class="editNewRoleFrom">
          <el-form-item label="权限组名称" prop="role_name">
            <el-input placeholder="请输入权限组名称" v-model="editNewRoleFrom.role_name" clearable></el-input>
          </el-form-item>
          <el-form-item label="权限" prop="role">
            <el-tree :data="allMenus" ref="edittree" show-checkbox default-expand-all node-key="id" highlight-current :props="{label: 'name', children: 'children'}"></el-tree>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editRoleVisible = false">取 消</el-button>
          <el-button type="primary" @click="editRoleSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Role',
  data () {
    return {
      addRoleVisible: false,
      editRoleVisible: false,
      // 所有已添加角色
      roleList: [],
      // 所有菜单
      allMenus: {},
      addNewRoleFrom: {
        role_name: '',
        role: []
      },
      roleMenu: [],
      editNewRoleFrom: {
        id: '',
        role_name: '',
        role: []
      },
      addNewRoleRules: {},
      editNewRoleRules: {}
    }
  },
  created () {
    this.get_role_list()
    this.get_all_menus()
  },
  methods: {
    // 获取所有权限列表
    async get_role_list () {
      const { data: res } = await this.$http.get('admin/role-list')
      if (res.meta.status === 200) {
        this.roleList = res.data
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 获取所有权限菜单
    async get_all_menus () {
      const { data: res } = await this.$http.get('admin/get-menu-list')
      if (res.meta.status === 200) {
        this.allMenus = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async handleEdit (id) {
      const { data: res } = await this.$http.get('admin/single-role/' + id)
      if (res.meta.status === 200) {
        this.editNewRoleFrom.id = res.data.id
        this.editNewRoleFrom.role_name = res.data.name
        this.editNewRoleFrom.role = res.data.roles
        this.editRoleVisible = true
        setTimeout(() => {
          this.$refs.edittree.setCheckedKeys(res.data.roles)
        }, 1000)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    addRole () {
      this.addRoleVisible = true
      setTimeout(() => {
        // this.$refs.addtree.resetFields()
        this.$refs.addtree.setCheckedKeys([])
      }, 1000)
    },
    handleAdd () {
      console.log('handleEdit')
    },
    async handleDelete (id) {
      const { data: res } = await this.$http.delete('admin/single-role/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.get_role_list()
      } else {
        this.$message.error('删除失败')
      }
    },
    // 添加新权限提交表单按钮
    async addRoleSubmit () {
      if (this.addNewRoleFrom.role_name === '') {
        this.$message.error('请填写权限组名称')
      } else {
        this.addNewRoleFrom.role = this.$refs.addtree.getCheckedKeys()
        const { data: res } = await this.$http.post('admin/single-role/0', this.addNewRoleFrom)
        if (res.meta.status === 200) {
          this.$message.success(res.meta.msg)
          this.get_role_list()
          this.addRoleVisible = false
        } else {
          this.$message.error(res.meta.msg)
        }
      }
    },
    // 修改权限提交表单按钮
    async editRoleSubmit () {
      this.editNewRoleFrom.role = this.$refs.edittree.getCheckedKeys()
      if (this.editNewRoleFrom.role_name === '') {
        this.$message.error('请填写权限组名称')
      } else {
        const { data: res } = await this.$http.put('admin/single-role/' + this.editNewRoleFrom.id, this.editNewRoleFrom)
        if (res.meta.status === 200) {
          this.$message.success(res.meta.msg)
          this.get_role_list()
          this.editRoleVisible = false
        } else {
          this.$message.error(res.meta.msg)
        }
      }
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
