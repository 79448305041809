<template>
  <div class="question-page">
    <div class="page-title">
      提问管理
    </div>
    <div class="page-body">
      <div class="question-list">
        <el-row :gutter="20" style="padding: 1rem 0">
          <el-col :span="6">
            <el-input placeholder="请输入问题标题" v-model="queryInfo.seachwords" clearable @clear="getQuestionList">
              <el-button slot="append" icon="el-icon-search" @click="getQuestionList"></el-button>
            </el-input>
          </el-col>
        </el-row>
        <!--用户列表问题-->
        <el-table :data="questionList" style="width: 100%" border stripe>
          <el-table-column type="expand">
            <template slot-scope="props">
              <div class="answer-list" v-if="props.row.answer.length > 0">
                <div class="answer-item" v-for="item in props.row.answer" :key="item.id" :index="item.id">
                  <div class="item-user">
                    <span> 回复用户：</span>
                    <p>{{ item.answer_user }}</p>
                  </div>
                  <div class="item-content">
                    {{ item.answer }}
                  </div>
                  <div class="tool">
                    <div class="time">
                      {{ item.creat_time }}
                    </div>
                    <el-tooltip content="删除" placement="top" :enterable="false">
                      <el-button type="warning" icon="el-icon-delete" size="mini" @click="deleteAnswer(item.id)"></el-button>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div class="answer-list" v-else style="text-align: center;">
                暂无评论
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="ID" width="60px"></el-table-column>
          <el-table-column prop="title" label="问题标题"></el-table-column>
          <el-table-column prop="content" label="问题内容"></el-table-column>
          <el-table-column prop="question_user" label="提问用户"></el-table-column>
          <el-table-column prop="answer_num" label="回答数"></el-table-column>
          <el-table-column prop="views" label="浏览数"></el-table-column>
          <el-table-column prop="creat_time" label="提问时间"></el-table-column>
          <el-table-column label="操作" width="250px">
            <template slot-scope="scope">
              <el-tooltip content="编辑" placement="top" :enterable="false">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="editItem(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" :enterable="false">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip content="回答" placement="top" :enterable="false">
                <el-button type="success" icon="el-icon-chat-round" size="mini" @click="answerItem(scope.row.id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--分页问题-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[5, 10, 50, 100]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryInfo.total" style="padding: 1rem 0;text-align: center">
        </el-pagination>
      </div>
      <!--编辑问题对话框-->
      <el-dialog :close-on-click-modal="false" title="编辑问题" :visible.sync="editItemVisible" width="50%" @close="closeEditFrom">
        <!--内容主体-->
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="问题标题" prop="title">
            <el-input v-model="editForm.title" maxlength="30" show-word-limit></el-input>
          </el-form-item>
          <el-form-item label="问题内容" prop="content">
            <el-input type="textarea" maxlength="250" show-word-limit :autosize="{ minRows: 4, maxRows: 8}" placeholder="请输入提问内容" v-model="editForm.content"></el-input>
          </el-form-item>
        </el-form>
        <!--底部按钮问题-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="editItemVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </span>
      </el-dialog>
      <!--回答问题对话框-->
      <el-dialog :close-on-click-modal="false" title="回答问题" :visible.sync="answerItemVisible" width="50%" @close="closeAnswerFrom">
        <!--内容主体-->
        <el-form :model="answerForm" :rules="answerRules" ref="answerRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="回答内容" prop="content">
            <el-input type="textarea" maxlength="250" show-word-limit :autosize="{ minRows: 4, maxRows: 8}" placeholder="请输入提问内容" v-model="answerForm.content"></el-input>
          </el-form-item>
        </el-form>
        <!--底部按钮问题-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="answerItemVisible = false">取 消</el-button>
        <el-button type="primary" @click="answerSubmit">确 定</el-button>
      </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Question',
  data () {
    return {
      questionList: [],
      // 获取问题列表的参数对象
      queryInfo: {
        // 搜索
        seachwords: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 5,
        // 总数据条数
        total: 0
      },
      editItemVisible: false,
      editForm: {
        id: '',
        title: '',
        content: ''
      },
      // 添加表单的验证规则对象
      editRules: {
        title: [
          { required: true, message: '请输入问题标题', trigger: 'blur' },
          { min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入问题内容', trigger: 'blur' },
          { min: 3, max: 250, message: '长度在 3 到 250 个字符', trigger: 'blur' }
        ]
      },
      answerItemVisible: false,
      answerForm: {
        id: '',
        content: ''
      },
      // 添加表单的验证规则对象
      answerRules: {
        content: [
          { required: true, message: '请输入回答内容', trigger: 'blur' },
          { min: 3, max: 250, message: '长度在 3 到 250 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getQuestionList()
  },
  methods: {
    async getQuestionList () {
      const { data: res } = await this.$http.get('admin/question-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.questionList = res.data.question_list
        this.queryInfo.total = res.data.totalpage
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听 每页显示多少改变事件
    handleSizeChange (newsize) {
      this.queryInfo.pagesize = newsize
      this.getQuestionList()
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getQuestionList()
    },
    async editItem (id) {
      const { data: res } = await this.$http.get('admin/question/' + id)
      if (res.meta.status === 200) {
        this.editForm.id = id
        this.editForm.title = res.data.title
        this.editForm.content = res.data.content
        this.editItemVisible = true
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    closeEditFrom () {
      this.$refs.editRef.resetFields()
    },
    answerItem (id) {
      this.answerForm.id = id
      this.answerItemVisible = true
    },
    async answerSubmit () {
      const { data: res } = await this.$http.post('admin/answer', this.answerForm)
      if (res.meta.status === 200) {
        this.getQuestionList()
        this.$message.success(res.meta.msg)
        this.answerItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    closeAnswerFrom () {
      this.$refs.answerRef.resetFields()
    },
    async editSubmit () {
      const { data: res } = await this.$http.put('admin/question/' + this.editForm.id, this.editForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.editRef.resetFields()
        this.editItemVisible = false
        this.getQuestionList()
      }
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('admin/question/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.getQuestionList()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    //  删除问题回答
    async deleteAnswer (id) {
      const { data: res } = await this.$http.delete('admin/delete-answer/' + id)
      if (res.meta.status === 200) {
        this.getQuestionList()
        this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    dateFormat: function (row, column) {
      var date = row[column.property]
      if (date === undefined || date === null) {
        return ''
      }
      return this.$moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped>
.answer-list{}
.answer-list .answer-item{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.answer-list .answer-item .item-user{
  width: 220px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.answer-list .answer-item .item-user span{
  font-weight: bold;
}
.answer-list .answer-item .item-user p{}
.answer-list .answer-item .item-content{
  flex: 1;
  padding: 10px;
}
.answer-list .answer-item .tool{
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.answer-list .answer-item .tool .time{
  margin-right: 10px;
}
</style>
