<template>
  <div class="welcome-page">
    系统欢迎页面
  </div>
</template>

<script>
export default {
  name: 'Welcome'
}
</script>

<style scoped>

</style>
