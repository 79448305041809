<template>
  <div class="login-page">
    <div class="login-card">
      <div class="left">
        <el-carousel :interval="5000" arrow="always">
          <el-carousel-item>
            <img class="login-banenr" src="../assets/images/login-banner1.png"/>
          </el-carousel-item>
          <el-carousel-item>
            <img class="login-banenr" src="../assets/images/login-banner2.png"/>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="right">
        <div class="change">
          <div class="title">
            <span v-if="changeData.account">账号登录</span>
            <span v-else>扫码登录</span>
          </div>
          <div class="icon" @click="changeLogin">
            <img v-if="!changeData.account" src="../assets/images/by-pc.png"/>
            <img v-else src="../assets/images/by-code.png"/>
          </div>
        </div>
        <div class="login-from" v-if="changeData.account">
          <el-form label-position="top" :model="loginFrom" :rules="rules" ref="loginRef" label-width="100px">
            <el-form-item label="用户名/手机号" prop="username">
              <el-input v-model="loginFrom.username" clearable></el-input>
            </el-form-item>
            <el-form-item label="登录密码" prop="password">
              <el-input v-model="loginFrom.password" clearable @keyup.enter.native="account_login"></el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="loginFrom.remember">记住账号</el-checkbox>
              <el-link icon="el-icon-warning-outline" :underline="false" style="float: right;">忘记密码？</el-link>
            </el-form-item>
            <el-form-item style="text-align: right;">
              <el-button type="primary" @click="account_login">登录</el-button>
              <el-button @click="resetForm('login')">清空</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="sacn-from" v-else>
          <div class="ewm">
            <img src="../assets/images/ewm.jpg"/>
          </div>
          <div class="tips">微信扫描上方二维码，完成登录</div>
        </div>
      </div>
    </div>
    <div class="copyright">
      这里填写公司版权信息
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      loginFrom: {
        username: '',
        password: '',
        remember: false
      },
      changeData: {
        account: true
      },
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入登录密码', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    changeLogin () {
      this.changeData.account = !this.changeData.account
    },
    async account_login () {
      this.$refs.loginRef.validate(async valid => {
        if (!valid) return
        // const result = await this.$http.post("login", this.loginForm);
        // login为请求路径，this.loginForm为请求参数
        const { data: res } = await this.$http.post('/admin/login', this.loginFrom)
        if (res.meta.status !== 200) {
          this.$message.error(res.meta.msg)
        } else {
          this.$message.success('登录成功')
          this.$store.commit('login', { username: res.data.username, token: res.data.token })
          setTimeout(() => {
            this.$router.push('/home')
          }, 1000)
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style scoped>
.login-page{
  width: 100%;
  height: 100vh;
  background-image: url("../assets/images/login-bg.png");
  background-size: cover;
  position: relative;
}
.login-card{
  width: 700px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.5);
  box-shadow: 0 0 8px 8px rgba(0,0,0,0.1);
  border-radius: 0.4rem;
  padding: 20px;
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 350px);
}
.login-card .left{
  width: 50%;
}
.login-card .right{
  width: 50%;
  padding-left: 20px;
  height: 360px;
}
/deep/.el-carousel__container{
  height: 360px;
}
.login-banenr{
  width: 100%;
  height: 100%;
  border-radius: 0.4rem 0 0 0.4rem;
}
.right .change{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.right .change .title{
  font-size: 18px;
  font-weight: bold;
  color: rgba(0,0,0,0.6);
}
.right .change .icon{
  width: 2rem;
  cursor: pointer;
}
.right .change .icon img{
  width: 100%;
}
/deep/.el-form--label-top .el-form-item__label{
  padding: 0;
}
.sacn-from .ewm{
  width: 60%;
  margin: 40px 20%;
}
.sacn-from .ewm img{
  width: 100%;
}
.sacn-from .tips{
  text-align: center;
  font-size: 14px;
}
.login-page .copyright{
  position: absolute;
  bottom: 40px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: rgba(0,0,0,0.4);
  font-weight: bold;
}
</style>
