<template>
  <div class="home-page">
    <el-container>
      <el-aside width="auto">
        <LeftMenu></LeftMenu>
      </el-aside>
      <el-container>
        <el-header>
          <Header></Header>
        </el-header>
        <el-main>
          <Main></Main>
        </el-main>
        <el-footer>
          <Footer></Footer>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from '@/components/commons/Header'
import Footer from '@/components/commons/Footer'
import LeftMenu from '@/components/commons/LeftMenu'
import Main from '@/components/commons/Main'
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    LeftMenu,
    Main
  }
}
</script>

<style scoped>
.el-header, .el-footer {
  background-color: #B3C0D1;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-header{
  background-color: #FFFFFF;
}
.el-aside {
  background-color: #D3DCE6;
  color: #333;
  text-align: center;
  line-height: 200px;
  position: relative;
  overflow: hidden;
}

.el-main {
  background-color: #E9EEF3;
  color: #333;
  text-align: center;
  line-height: 160px;
}

.el-container {
  height: 100%;
}
.home-page{
  width: 100%;
  height: 100vh;
}
</style>
