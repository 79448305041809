<template>
  <div class="jurisdiction-page">
    <div class="page-title">
      用户权限
    </div>
    <div class="page-body">
      <el-form :model="pageForm" :rules="rules" ref="pageFormRef" label-width="160px" class="from-area">
        <el-form-item label="发布内容间隔:" prop="release_min" style="width: 50%">
          <el-input v-model="pageForm.release_min" type="num">
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
        <el-form-item label="回复内容间隔:" prop="reply_min" style="width: 50%">
          <el-input v-model="pageForm.reply_min" type="num">
            <template slot="append">分钟</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交信息</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Jurisdiction',
  data () {
    return {
      pageForm: {
        release_min: 0,
        reply_min: 0
      },
      rules: {}
    }
  },
  created () {
    this.get_page_info()
  },
  methods: {
    // 获取站点信息
    async get_page_info () {
      const { data: res } = await this.$http.get('admin/jurisdiction')
      if (res.meta.status === 200) {
        this.pageForm.release_min = res.data.release_min
        this.pageForm.reply_min = res.data.reply_min
      }
    },
    async submitForm () {
      this.$refs.pageFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/jurisdiction', this.pageForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_page_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.pageFormRef.resetFields()
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
