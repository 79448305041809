<template>
  <div class="member-page">
    <div class="page-title">
      会员管理
    </div>
    <div class="page-body">
      <el-table :data="allUser" stripe style="width: 100%">
        <el-table-column prop="id" label="#ID" width="180"></el-table-column>
        <el-table-column prop="username" label="用户名" width="180"></el-table-column>
        <el-table-column prop="nickname" label="昵称"></el-table-column>
        <el-table-column prop="level" label="用户等级"></el-table-column>
        <el-table-column prop="avatar" label="头像">
          <template slot-scope="scope">
            <el-avatar size="small" :src="scope.row.avatar"></el-avatar>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.row.id)">编辑</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.row.id)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--修改用户-->
    <el-dialog title="修改用户" :close-on-click-modal="false" :visible.sync="editDialogVisible" width="30%" :before-close="editHandleClose">
      <el-form :model="editForm" :rules="editRules" ref="editFormRef" label-width="100px">
        <el-form-item label="用户头像" prop="avatar">
          <file-pond
            name="avatar"
            ref="pond"
            label-idle="Drop files here..."
            v-bind:allow-multiple="false"
            accepted-file-types="image/jpeg, image/png"
            :server="uploadServer"
            v-bind:files="editForm.avatar"
            v-on:init="handleFilePondInit"
            :chunkUploads="true"
            maxFileSize="2MB"
            :onprocessfile="onEditfile"
            :onremovefile="editRemoveAvatar"
          />
        </el-form-item>
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="editForm.nickname"></el-input>
        </el-form-item>
        <el-form-item label="绑定手机号" prop="phone">
          <el-input v-model="editForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="邮箱号" prop="email">
          <el-input v-model="editForm.email"></el-input>
        </el-form-item>
        <el-form-item label="介绍" prop="introduce">
          <el-input type="textarea" placeholder="请输入内容" v-model="editForm.introduce" maxlength="200" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="用户等级" prop="level">
          <el-select v-model="editForm.level" placeholder="请选择">
            <el-option label="1级会员" :value="1"></el-option>
            <el-option label="普通用户" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="帐号状态" prop="locked">
          <el-switch v-model="editForm.locked" :active-value="1" :inactive-value="0" active-text="正常" inactive-text="锁定"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="editSure">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Member',
  data () {
    return {
      allUser: [],
      editDialogVisible: false,
      editForm: {
        id: '',
        avatar: [],
        avatar_id: '',
        username: '',
        nickname: '',
        phone: '',
        email: '',
        introduce: '',
        level: '', // 用户等级，默认0普通会员
        locked: '' // 账户是否锁定1被锁定0正常
      },
      editRules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        avatarId: [
          { required: true, message: '请上传头像', trigger: 'change' }
        ]
      },
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      }
    }
  },
  created () {
    this.get_all_user()
  },
  methods: {
    async get_all_user () {
      const { data: res } = await this.$http.get('admin/all-users')
      if (res.meta.status === 200) {
        this.allUser = res.data
        // this.$message.success(res.meta.msg)
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    onEditfile (error, file) {
      console.log(error)
      this.editForm.avatarId = file.serverId
    },
    editRemoveAvatar () {
      this.editForm.avatarId = ''
    },
    // 修改用户按钮
    async handleEdit (userid) {
      const { data: res } = await this.$http.get('admin/single-users/' + userid)
      if (res.meta.status === 200) {
        this.editForm.id = res.data.id
        if (res.data.avatar) {
          this.editForm.avatar = [{
            // the server file reference
            source: res.data.avatar,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.avatar
              }
            }
          }]
        } else {
          this.editForm.avatar = []
        }
        this.editForm.avatar_id = res.data.avatar_id

        this.editForm.username = res.data.username
        this.editForm.nickname = res.data.nickname
        this.editForm.phone = res.data.phone
        this.editForm.email = res.data.email
        this.editForm.introduce = res.data.introduce
        this.editForm.level = res.data.level
        this.editForm.locked = res.data.locked
      }
      this.editDialogVisible = true
    },
    // 关闭修改弹窗
    editHandleClose () {
      this.$refs.editFormRef.resetFields()
      this.editDialogVisible = false
    },
    // 确认修改用户
    async editSure () {
      const that = this
      this.$refs.editFormRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await that.$http.put('admin/single-users/' + that.editForm.id, that.editForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.$refs.editFormRef.resetFields()
            this.get_all_user()
            this.editDialogVisible = false
          } else {
            this.$message.error(res.meta.msg)
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    // 删除用户
    async handleDelete (id) {
      const { data: res } = await this.$http.delete('admin/single-users/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.get_all_user()
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
