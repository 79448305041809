import Vue from 'vue'
import Vuex from 'vuex'
import persistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {
      username: '',
      token: ''
    }
  },
  mutations: {
    login (state, { username, token }) {
      state.userInfo.username = username
      state.userInfo.token = token
    },
    logout (state) {
      state.userInfo.username = ''
      state.userInfo.token = ''
    }
  },
  actions: {
  },
  modules: {
  },
  plugins: [persistedState()]
})
