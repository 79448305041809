<template>
  <div class="article-page">
    <div class="page-title">
      百科文章管理
    </div>
    <div class="page-body">
      <div class="article-list">
        <el-row :gutter="20" style="padding: 1rem 0">
          <el-col :span="6">
            <el-input placeholder="请输入文章名称" v-model="queryInfo.seachwords" clearable @clear="getArticleList">
              <el-button slot="append" icon="el-icon-search" @click="getArticleList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4" :offset="14" style="text-align: right">
            <el-button type="primary" @click="addItemVisible = true">添加百科文章</el-button>
          </el-col>
        </el-row>
        <!--用户列表区域-->
        <el-table :data="articleList" style="width: 100%" border stripe>
          <el-table-column prop="id" label="ID" width="60px"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="tid" label="归属百科分类"></el-table-column>
          <el-table-column prop="name" label="文章名称"></el-table-column>
          <el-table-column prop="view" label="浏览次数"></el-table-column>
          <el-table-column label="操作" width="250px">
            <template slot-scope="scope">
              <el-tooltip content="编辑" placement="top" :enterable="false">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="editItem(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" :enterable="false">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row.id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--分页区域-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[5, 10, 50, 100]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryInfo.total" style="padding: 1rem 0;text-align: center">
        </el-pagination>
      </div>
      <!--添加文章对话框-->
      <el-dialog :close-on-click-modal="false" title="添加百科文章" :visible.sync="addItemVisible" width="50%" @close="closeAddFrom">
        <!--内容主体区域-->
        <el-form :model="addForm" :rules="addRules" ref="addRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="addForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属百科分类" prop="tid">
            <el-select v-model="addForm.tid" placeholder="请选择">
              <el-option
                v-for="item in wikiSortList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="百科文章标题" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="百科文章标题（英文）" prop="enname">
            <el-input v-model="addForm.enname"></el-input>
          </el-form-item>
          <el-form-item label="百科文章标题（日文）" prop="jpname">
            <el-input v-model="addForm.jpname"></el-input>
          </el-form-item>
          <el-form-item label="百科文章缩略图" prop="img">
            <file-pond
              name="img"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="addForm.img"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="addImgonprocess"
              :onremovefile="addRemoveImg"
            />
          </el-form-item>
          <el-form-item label="百科文章内容" prop="content">
            <ckeditor :editor="editor" @ready="onReady" v-model="addForm.content" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="百科文章内容（英文）" prop="enprofiles">
            <ckeditor :editor="editor" @ready="onReady" v-model="addForm.encontent" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="百科文章内容（日文）" prop="jpprofiles">
            <ckeditor :editor="editor" @ready="onReady" v-model="addForm.jpcontent" :config="editorConfig"></ckeditor>
          </el-form-item>
        </el-form>
        <!--底部按钮区域-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="addItemVisible = false">取 消</el-button>
        <el-button type="primary" @click="addSubmit">确 定</el-button>
      </span>
      </el-dialog>
      <!--编辑百科文章对话框-->
      <el-dialog :close-on-click-modal="false" title="编辑百科文章" :visible.sync="editItemVisible" width="50%" @close="closeEditFrom">
        <!--内容主体区域-->
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="editForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属百科分类" prop="tid">
            <el-select v-model="editForm.tid" placeholder="请选择">
              <el-option
                v-for="item in wikiSortList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="百科文章标题" prop="name">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="百科文章标题（英文）" prop="enname">
            <el-input v-model="editForm.enname"></el-input>
          </el-form-item>
          <el-form-item label="百科文章标题（日文）" prop="jpname">
            <el-input v-model="editForm.jpname"></el-input>
          </el-form-item>
          <el-form-item label="百科文章缩略图" prop="img">
            <file-pond
              name="img"
              ref="pond"
              label-idle="Drop files here..."
              v-bind:allow-multiple="false"
              accepted-file-types="image/jpeg, image/png"
              :server="uploadServer"
              v-bind:files="editForm.img"
              v-on:init="handleFilePondInit"
              :chunkUploads="true"
              maxFileSize="2MB"
              :onprocessfile="editImgonprocess"
              :onremovefile="editRemoveImg"
            />
          </el-form-item>
          <el-form-item label="百科文章内容" prop="content">
            <ckeditor :editor="editor" @ready="onReady" v-model="editForm.content" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="百科文章内容（英文）" prop="encontent">
            <ckeditor :editor="editor" @ready="onReady" v-model="editForm.encontent" :config="editorConfig"></ckeditor>
          </el-form-item>
          <el-form-item label="百科文章内容（日文）" prop="jpcontent">
            <ckeditor :editor="editor" @ready="onReady" v-model="editForm.jpcontent" :config="editorConfig"></ckeditor>
          </el-form-item>
        </el-form>
        <!--底部按钮区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editItemVisible = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'
import MyUploadAdapter from '@/assets/js/MyUploadAdapter'

export default {
  name: 'Wiki',
  data () {
    return {
      articleList: [],
      // 获取文章列表的参数对象
      queryInfo: {
        // 搜索
        seachwords: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 5,
        // 总数据条数
        total: 0
      },
      addItemVisible: false,
      wikiSortList: [],
      addForm: {
        sort: '',
        tid: '',
        name: '',
        enname: '',
        jpname: '',
        img: [],
        img_id: '',
        content: '',
        encontent: '',
        jpcontent: ''
      },
      // 添加表单的验证规则对象
      addRules: {
        tid: [
          { required: true, message: '请选择归属百科分类', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      },
      editItemVisible: false,
      editForm: {
        id: '',
        sort: '',
        tid: '',
        name: '',
        enname: '',
        jpname: '',
        img: [],
        img_id: '',
        content: '',
        encontent: '',
        jpcontent: ''
      },
      // 修改表单的验证规则对象
      editRules: {
        tid: [
          { required: true, message: '请选择归属百科分类', trigger: 'change' }
        ],
        name: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ]
      },
      uploadSeting: {
        multiple: false, // 是否可以多选文件
        action: axios.defaults.baseURL + 'upload',
        auto_upload: true, // 是否立即上传
        limit: 1, // 允许的最大上传数量
        // 图片上传请求头
        headers: {
          Authorization: window.sessionStorage.getItem('token'),
          username: window.sessionStorage.getItem('username')
        }
      },
      uploadServer: {
        url: axios.defaults.baseURL,
        headers: {
          Authorization: this.$store.state.userInfo.token,
          username: this.$store.state.userInfo.username
        },
        process: '/admin/upload/process',
        fetch: null, // 自定义fetch方法接收url要获取的内容和一组FilePond回调方法，以将控制权返回给FilePond。
        revert: null, // 还原
        remove: '/admin/upload/remove',
        load: '/admin/upload/load/'
      },
      disabled: false,
      editor: DecoupledEditor,
      // ckeditor 配置
      editorConfig: {
        // Run the editor with the German UI.
        toolbar: {
          items: [
            'heading',
            '|',
            'fontSize',
            'fontFamily',
            '|',
            'bold',
            'highlight',
            'fontColor',
            'fontBackgroundColor',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'alignment',
            '|',
            'numberedList',
            'bulletedList',
            '|',
            'indent',
            'outdent',
            '|',
            'link',
            'blockQuote',
            'horizontalLine',
            'imageUpload',
            'insertTable',
            '|',
            'exportWord',
            'exportPdf',
            'undo',
            'redo'
          ]
        },
        language: 'zh-cn',
        image: {
          toolbar: [
            'imageTextAlternative',
            'imageStyle:full',
            'imageStyle:side'
          ]
        },
        table: {
          contentToolbar: [
            'tableColumn',
            'tableRow',
            'mergeTableCells',
            'tableCellProperties',
            'tableProperties'
          ]
        }
      }
    }
  },
  created () {
    this.getArticleList()
    this.getWikiList()
  },
  methods: {
    async getArticleList () {
      const { data: res } = await this.$http.get('admin/wiki-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.articleList = res.data.wiki_list
        this.queryInfo.total = res.data.totalpage
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async getWikiList () {
      const { data: res } = await this.$http.get('admin/wiki-sort-list')
      if (res.meta.status === 200) {
        this.wikiSortList = res.data
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听 每页显示多少改变事件
    handleSizeChange (newsize) {
      this.queryInfo.pagesize = newsize
      this.getArticleList()
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getArticleList()
    },
    async addSubmit () {
      const { data: res } = await this.$http.post('admin/wiki/1', this.addForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.addRef.resetFields()
        this.getArticleList()
        this.addItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async editSubmit () {
      const { data: res } = await this.$http.put('admin/wiki/' + this.editForm.id, this.editForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.editRef.resetFields()
        this.getArticleList()
        this.editItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    handleFilePondInit () {
      // console.log('FilePond has initialized')
    },
    addImgonprocess (error, file) {
      console.log(error)
      this.addForm.img_id = file.serverId
    },
    addRemoveImg () {
      this.addForm.img_id = ''
    },
    editImgonprocess (error, file) {
      console.log(error)
      this.editForm.img_id = file.serverId
    },
    editRemoveImg () {
      this.editForm.img_id = ''
      this.editForm.img = []
    },
    // 监听添加用户对话框关闭事件
    closeAddFrom () {
      this.$refs.addRef.resetFields()
    },
    // 监听编辑用户对话框关闭事件
    closeEditFrom () {
      this.$refs.editRef.resetFields()
    },
    async editItem (id) {
      const { data: res } = await this.$http.get('admin/wiki/' + id)
      if (res.meta.status === 200) {
        this.editForm.id = id
        this.editForm.sort = res.data.sort
        this.editForm.tid = res.data.tid
        this.editForm.name = res.data.name
        this.editForm.enname = res.data.enname
        this.editForm.jpname = res.data.jpname
        if (res.data.img) {
          this.editForm.img = [{
            // the server file reference
            source: res.data.img,
            // set type to local to indicate an already uploaded file
            options: {
              type: 'local',
              // pass poster property
              metadata: {
                poster: res.data.img
              }
            }
          }]
        } else {
          this.editForm.img = []
        }
        this.editForm.img_id = res.data.img_id
        this.editForm.content = res.data.content
        this.editForm.encontent = res.data.encontent
        this.editForm.jpcontent = res.data.jpcontent
        this.$forceUpdate()
        // ws
        this.editItemVisible = true
      }
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('admin/wiki/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.getArticleList()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    dateFormat: function (row, column) {
      var date = row[column.property]
      if (date === undefined || date === null) {
        return ''
      }
      return this.$moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    },
    onReady (editor) {
      // Insert the toolbar before the editable area.
      editor.ui.getEditableElement().parentElement.insertBefore(
        editor.ui.view.toolbar.element,
        editor.ui.getEditableElement()
      )
      editor.plugins.get('FileRepository').createUploadAdapter = loader => {
        return new MyUploadAdapter(loader)
      }
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
