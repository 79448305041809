<template>
  <div class="integral-page">
    <div class="page-title">
      积分设置
    </div>
    <div class="page-body">
      <el-form :model="pageForm" :rules="rules" ref="pageFormRef" label-width="160px" class="from-area">
        <el-form-item label="是否开放积分:" prop="integral">
          <el-switch v-model="pageForm.integral" :active-value="1" :inactive-value="0" active-color="#ff4949" inactive-color="#13ce66" active-text="否" inactive-text="是"></el-switch>
        </el-form-item>
        <el-form-item label="用户注册发放积分:" prop="reg_integral" style="width: 50%">
          <el-input v-model="pageForm.reg_integral" type="num">
            <template slot="append">积分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="用户回复获得积分:" prop="reply_integral" style="width: 50%">
          <el-input v-model="pageForm.reply_integral" type="num">
            <template slot="append">积分</template>
          </el-input>
        </el-form-item>
        <el-form-item label="每天签到获得积分:" prop="signin_integral" style="width: 50%">
          <el-input v-model="pageForm.signin_integral" type="num">
            <template slot="append">积分</template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交信息</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Integral',
  data () {
    return {
      pageForm: {
        integral: 0,
        reg_integral: 0,
        reply_integral: 0,
        signin_integral: 0
      },
      rules: {}
    }
  },
  created () {
    this.get_page_info()
  },
  methods: {
    // 获取站点信息
    async get_page_info () {
      const { data: res } = await this.$http.get('admin/integral')
      if (res.meta.status === 200) {
        this.pageForm.integral = res.data.integral
        this.pageForm.reg_integral = res.data.reg_integral
        this.pageForm.reply_integral = res.data.reply_integral
        this.pageForm.signin_integral = res.data.signin_integral
      }
    },
    async submitForm () {
      this.$refs.pageFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/integral', this.pageForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_page_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.pageFormRef.resetFields()
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
