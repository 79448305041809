<template>
  <div class="ability-page">
    <div class="page-title">
      站点功能
    </div>
    <el-tabs tab-position="left" style="height: 100%;">
      <el-tab-pane label="短信配置">
        <el-form :model="abilityForm" :rules="rules" ref="abilityFormRef" label-width="200px" class="from-area">
          <el-form-item label="腾讯云个人密钥ID:" prop="tencent_api_id">
            <el-input v-model="abilityForm.tencent_api_id"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/cam/capi" target="_blank">点击查看</a>
            </span>
          </el-form-item>
          <el-form-item label="腾讯云个人密钥SecretKey:" prop="tencent_api_key">
            <el-input v-model="abilityForm.tencent_api_key"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/cam/capi" target="_blank">点击查看</a>
            </span>
          </el-form-item>
          <el-form-item label="短信应用APPID:" prop="sms_appid">
            <el-input v-model="abilityForm.sms_appid"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/smsv2/app-manage" target="_blank">点击查看</a>
            </span>
          </el-form-item>
          <el-form-item label="短信签名:" prop="sms_sig">
            <el-input v-model="abilityForm.sms_sig"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/smsv2/csms-sign" target="_blank">点击查看</a>
            </span>
          </el-form-item>
          <el-form-item label="登录模板ID:" prop="sms_login_id">
            <el-input v-model="abilityForm.sms_login_id"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/smsv2/csms-sign" target="_blank">点击查看</a>
            </span>
          </el-form-item>
          <el-form-item label="注册模板ID:" prop="sms_reg_id">
            <el-input v-model="abilityForm.sms_reg_id"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/smsv2/csms-sign" target="_blank">点击查看</a>
            </span>
          </el-form-item>
          <el-form-item label="找回密码模板ID:" prop="sms_findpd_id">
            <el-input v-model="abilityForm.sms_findpd_id"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/smsv2/csms-sign" target="_blank">点击查看</a>
            </span>
          </el-form-item>
          <el-form-item label="普通短信模板ID:" prop="sms_ordinary_id">
            <el-input v-model="abilityForm.sms_ordinary_id"></el-input>
            <span class="input-tips">
              <a href="https://console.cloud.tencent.com/smsv2/csms-sign" target="_blank">点击查看</a>
            </span>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="邮箱配置">
        <el-form :model="abilityForm" :rules="rules" ref="abilityFormRef" label-width="200px" class="from-area">
          <el-form-item label="SMTP服务器:" prop="smtp_client">
            <el-input v-model="abilityForm.smtp_client"></el-input>
          </el-form-item>
          <el-form-item label="SMTP用户名:" prop="smtp_username">
            <el-input v-model="abilityForm.smtp_username"></el-input>
          </el-form-item>
          <el-form-item label="SMTP密码:" prop="smtp_password">
            <el-input v-model="abilityForm.smtp_password"></el-input>
          </el-form-item>
          <el-form-item label="TCP端口:" prop="tcp_port">
            <el-input v-model="abilityForm.tcp_port"></el-input>
          </el-form-item>
          <el-form-item label="发送邮件的邮箱账号:" prop="mail_account">
            <el-input v-model="abilityForm.mail_account"></el-input>
          </el-form-item>
          <el-form-item label="发件人姓名:" prop="mail_send_username">
            <el-input v-model="abilityForm.mail_send_username"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="七牛云配置">
        <el-form :model="abilityForm" :rules="rules" ref="abilityFormRef" label-width="200px" class="from-area">
          <el-form-item label="七牛云accessKey:" prop="qiniu_accessKey">
            <el-input v-model="abilityForm.qiniu_accessKey"></el-input>
          </el-form-item>
          <el-form-item label="七牛云secretKey:" prop="qiniu_secretKey">
            <el-input v-model="abilityForm.qiniu_secretKey"></el-input>
          </el-form-item>
          <el-form-item label="七牛云bucket:" prop="qiniu_bucket">
            <el-input v-model="abilityForm.qiniu_bucket"></el-input>
          </el-form-item>
          <el-form-item label="url（加速域名）:" prop="qiniu_url">
            <el-input v-model="abilityForm.qiniu_url">
              <template slot="prepend">https://</template>
            </el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="腾讯云配置">
        <el-form :model="abilityForm" :rules="rules" ref="abilityFormRef" label-width="200px" class="from-area">
          <el-form-item label="实时音视频SDKAPPID:" prop="trtc_appid">
            <el-input v-model="abilityForm.trtc_appid"></el-input>
          </el-form-item>
          <el-form-item label="实时音视频SDKSECRET:" prop="trtc_secret">
            <el-input v-model="abilityForm.trtc_secret"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="公众号配置">
        <el-form :model="abilityForm" :rules="rules" ref="abilityFormRef" label-width="200px" class="from-area">
          <el-form-item label="开发者ID(AppID):" prop="wechat_appid">
            <el-input v-model="abilityForm.wechat_appid"></el-input>
          </el-form-item>
          <el-form-item label="开发者秘钥(AppSecret):" prop="wechat_secret">
            <el-input v-model="abilityForm.wechat_secret"></el-input>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <div class="from-btn">
      <el-button type="primary" @click="submitForm">提交信息</el-button>
      <el-button @click="resetForm">重置</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ability',
  data () {
    return {
      abilityForm: {
        tencent_api_id: '',
        tencent_api_key: '',
        sms_appid: '',
        sms_sig: '',
        sms_login_id: '',
        sms_reg_id: '',
        sms_findpd_id: '',
        sms_ordinary_id: '',
        smtp_client: '',
        smtp_username: '',
        smtp_password: '',
        tcp_port: '',
        mail_account: '',
        mail_send_username: '',
        qiniu_accessKey: '',
        qiniu_secretKey: '',
        qiniu_bucket: '',
        qiniu_url: '',
        trtc_appid: '',
        trtc_secret: '',
        wechat_appid: '',
        wechat_secret: ''
      },
      rules: {
      }
    }
  },
  created () {
    this.get_ability_info()
  },
  methods: {
    // 获取站点信息
    async get_ability_info () {
      const { data: res } = await this.$http.get('admin/ability')
      if (res.meta.status === 200) {
        this.abilityForm.tencent_api_id = res.data.tencent_api_id
        this.abilityForm.tencent_api_key = res.data.tencent_api_key
        this.abilityForm.sms_appid = res.data.sms_appid
        this.abilityForm.sms_sig = res.data.sms_sig
        this.abilityForm.sms_login_id = res.data.sms_login_id
        this.abilityForm.sms_reg_id = res.data.sms_reg_id
        this.abilityForm.sms_findpd_id = res.data.sms_findpd_id
        this.abilityForm.sms_ordinary_id = res.data.sms_ordinary_id
        this.abilityForm.smtp_client = res.data.smtp_client
        this.abilityForm.smtp_username = res.data.smtp_username
        this.abilityForm.smtp_password = res.data.smtp_password
        this.abilityForm.tcp_port = res.data.tcp_port
        this.abilityForm.mail_account = res.data.mail_account
        this.abilityForm.mail_send_username = res.data.mail_send_username
        this.abilityForm.qiniu_accessKey = res.data.qiniu_accessKey
        this.abilityForm.qiniu_secretKey = res.data.qiniu_secretKey
        this.abilityForm.qiniu_bucket = res.data.qiniu_bucket
        this.abilityForm.qiniu_url = res.data.qiniu_url
        this.abilityForm.trtc_appid = res.data.trtc_appid
        this.abilityForm.trtc_secret = res.data.trtc_secret
        this.abilityForm.wechat_appid = res.data.wechat_appid
        this.abilityForm.wechat_secret = res.data.wechat_secret
      }
    },
    async submitForm () {
      this.$refs.abilityFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/ability', this.abilityForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_ability_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.abilityFormRef.resetFields()
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
.from-area{
  width: 40%;
}
.input-tips{
  font-size: 12px;
  color: #909399;
}
.from-btn{
  margin-top: 2rem;
  text-align: center;
}
</style>
