import { render, staticRenderFns } from "./Zlh.vue?vue&type=template&id=c800b674&scoped=true&"
import script from "./Zlh.vue?vue&type=script&lang=js&"
export * from "./Zlh.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c800b674",
  null
  
)

export default component.exports