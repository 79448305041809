<template>
  <div class="links-page">
    <div class="page-title">
      链接管理
    </div>
    <div class="page-body">
      <div class="link-list">
        <el-row :gutter="20" style="padding: 1rem 0">
          <el-col :span="6">
            <el-input placeholder="请输入链接名称" v-model="queryInfo.seachwords" clearable @clear="getLinkList">
              <el-button slot="append" icon="el-icon-search" @click="getLinkList"></el-button>
            </el-input>
          </el-col>
          <el-col :span="4" :offset="14" style="text-align: right">
            <el-button type="primary" @click="addItemVisible = true">添加链接</el-button>
          </el-col>
        </el-row>
        <!--链接分类列表区域-->
        <el-table :data="linkList" style="width: 100%" border stripe>
          <el-table-column prop="link_id" label="ID" width="60px"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="name" label="链接名称"></el-table-column>
          <el-table-column prop="link" label="链接"></el-table-column>
          <el-table-column label="操作" width="250px">
            <template slot-scope="scope">
              <el-tooltip content="编辑" placement="top" :enterable="false">
                <el-button type="primary" icon="el-icon-edit" size="mini" @click="editItem(scope.row.id)"></el-button>
              </el-tooltip>
              <el-tooltip content="删除" placement="top" :enterable="false">
                <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteItem(scope.row.id)"></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!--分页区域-->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[5, 10, 50, 100]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="queryInfo.total" style="padding: 1rem 0;text-align: center">
        </el-pagination>
      </div>
      <!--添加链接分类对话框-->
      <el-dialog :close-on-click-modal="false" title="添加链接" :visible.sync="addItemVisible" width="50%" @close="closeAddFrom">
        <!--内容主体区域-->
        <el-form :model="addForm" :rules="addRules" ref="addRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="addForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属链接分类" prop="tid">
            <el-select v-model="addForm.tid" placeholder="请选择">
              <el-option
                v-for="item in linkSort"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="链接名称" prop="name">
            <el-input v-model="addForm.name"></el-input>
          </el-form-item>
          <el-form-item label="链接" prop="link">
            <el-input v-model="addForm.link"></el-input>
          </el-form-item>
          <!--底部按钮区域-->
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="addItemVisible = false">取 消</el-button>
          <el-button type="primary" @click="addSubmit">确 定</el-button>
        </span>
      </el-dialog>
      <!--编辑链接分类对话框-->
      <el-dialog :close-on-click-modal="false" title="编辑链接分类" :visible.sync="editItemVisible" width="50%" @close="closeEditFrom">
        <!--内容主体区域-->
        <el-form :model="editForm" :rules="editRules" ref="editRef" label-position="top" label-width="160px" style="text-align: left;padding: 0px 80px">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="editForm.sort"></el-input>
          </el-form-item>
          <el-form-item label="归属链接分类" prop="tid">
            <el-select v-model="editForm.tid" placeholder="请选择">
              <el-option
                v-for="item in linkSort"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="链接名称" prop="name">
            <el-input v-model="editForm.name"></el-input>
          </el-form-item>
          <el-form-item label="链接" prop="link">
            <el-input v-model="editForm.link"></el-input>
          </el-form-item>
        </el-form>
        <!--底部按钮区域-->
        <span slot="footer" class="dialog-footer">
          <el-button @click="editItemVisible = false">取 消</el-button>
          <el-button type="primary" @click="editSubmit">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Links',
  data () {
    return {
      linkSort: [],
      linkList: [],
      // 获取列表的参数对象
      queryInfo: {
        // 搜索
        seachwords: '',
        // 当前页码
        pagenum: 1,
        // 每页显示条数
        pagesize: 5,
        // 总数据条数
        total: 0
      },
      addItemVisible: false,
      addForm: {
        sort: '',
        tid: '',
        name: '',
        link: ''
      },
      // 添加表单的验证规则对象
      addRules: {
        name: [
          { required: true, message: '请输入链接名称', trigger: 'blur' },
          { min: 2, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        tid: [
          { required: true, message: '请选择分类', trigger: 'blur' }
        ]
      },
      editItemVisible: false,
      editForm: {
        id: '',
        sort: '',
        tid: '',
        name: '',
        link: ''
      },
      // 修改表单的验证规则对象
      editRules: {
        name: [
          { required: true, message: '请输入链接名称', trigger: 'blur' },
          { min: 2, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' }
        ],
        tid: [
          { required: true, message: '请选择分类', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.getLinkList()
    this.getLinkSortList()
  },
  methods: {
    async getLinkSortList () {
      const { data: res } = await this.$http.get('admin/link-sort-list')
      if (res.meta.status === 200) {
        this.linkSort = res.data
      }
    },
    async getLinkList () {
      const { data: res } = await this.$http.get('admin/link-list', { params: this.queryInfo })
      if (res.meta.status === 200) {
        this.linkList = res.data.link_list
        this.queryInfo.total = res.data.totalpage
        this.queryInfo.pagenum = res.data.pagenum
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听 每页显示多少改变事件
    handleSizeChange (newsize) {
      this.queryInfo.pagesize = newsize
      this.getLinkList()
    },
    // 监听页码值改变事件
    handleCurrentChange (newpage) {
      this.queryInfo.pagenum = newpage
      this.getLinkList()
    },
    async addSubmit () {
      const { data: res } = await this.$http.post('admin/link/1', this.addForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.addRef.resetFields()
        this.getLinkList()
        this.addItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    async editSubmit () {
      const { data: res } = await this.$http.put('admin/link/' + this.editForm.id, this.editForm)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.$refs.editRef.resetFields()
        this.getLinkList()
        this.editItemVisible = false
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    // 监听添加用户对话框关闭事件
    closeAddFrom () {
      this.$refs.addRef.resetFields()
    },
    // 监听编辑用户对话框关闭事件
    closeEditFrom () {
      this.$refs.editRef.resetFields()
    },
    async editItem (id) {
      const { data: res } = await this.$http.get('admin/link/' + id)
      if (res.meta.status === 200) {
        this.editForm.id = id
        this.editForm.sort = res.data.sort
        this.editForm.name = res.data.name
        this.editForm.tid = res.data.tid
        this.editForm.link = res.data.link
        this.editItemVisible = true
      }
    },
    async deleteItem (id) {
      const { data: res } = await this.$http.delete('admin/link/' + id)
      if (res.meta.status === 200) {
        this.$message.success(res.meta.msg)
        this.getLinkList()
      } else {
        this.$message.error(res.meta.msg)
      }
    },
    dateFormat: function (row, column) {
      var date = row[column.property]
      if (date === undefined || date === null) {
        return ''
      }
      return this.$moment.unix(date).format('YYYY-MM-DD HH:mm:ss')
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
