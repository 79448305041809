<template>
  <div class="main-page">
    <div class="main-card">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Main'
}
</script>

<style scoped>
  .main-page{
    border-radius: 0.4rem;
    background-color: rgba(255,255,255,1);
    padding: 0.2rem;
  }
  .main-card{
    background-color: rgba(255,255,255,1);
    border-radius: 0.4rem;
    height: calc(100vh - 170px);
    line-height: 1.4rem;
    padding: 1.4rem;
    text-align: left;
    overflow-y: scroll;
  }
  .main-card::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 4px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 1px;
  }
  .main-card::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius   : 10px;
    background-color: rgba(103, 194, 58, 0.4);
    background-image: -webkit-linear-gradient(
      45deg,
      rgba(103, 194, 58, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
  }
  .main-card::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    background   : #FFFFFF;
    border-radius: 10px;
    border: 0;
  }
</style>
