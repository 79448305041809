<template>
  <div class="announcements-page">
    <div class="page-title">
      公告管理
    </div>
    <div class="page-body">
      <el-form :model="pageForm" :rules="rules" ref="pageFormRef" label-width="160px" class="from-area">
        <el-form-item label="公告内容:" prop="notice">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入公告内容"
            v-model="pageForm.notice">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">提交信息</el-button>
          <el-button @click="resetForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Announcements',
  data () {
    return {
      pageForm: {
        notice: ''
      },
      rules: {}
    }
  },
  created () {
    this.get_page_info()
  },
  methods: {
    // 获取站点信息
    async get_page_info () {
      const { data: res } = await this.$http.get('admin/announcements')
      if (res.meta.status === 200) {
        this.pageForm.notice = res.data.notice
      }
    },
    async submitForm () {
      this.$refs.pageFormRef.validate(async valid => {
        if (valid) {
          const { data: res } = await this.$http.post('/admin/announcements', this.pageForm)
          if (res.meta.status === 200) {
            this.$message.success(res.meta.msg)
            this.get_page_info()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm () {
      this.$refs.pageFormRef.resetFields()
    }
  }
}
</script>

<style scoped>
.page-title{
  font-size: 16px;
  font-weight: bold;
  color: #3a8ee6;
  border-left: 2px solid #3a8ee6;
  padding-left: 1rem;
  margin-bottom: 2rem;
}
</style>
